import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n';
import 'vant/lib/index.less';
import '@vant/touch-emulator';
import { push, replace, switchPage, getCurPage} from './utils/navigate'
import tool from '../src/utils/tools'
import Message from '@/components/jackToast/index';

import './utils/rem.js';
// Vue.prototype.$eventBus = new Vue();

Vue.use(VueI18n);
Vue.use(ElementUI);

const i18n = new VueI18n({
  locale: 'en-US', //切换语言
  messages: {
    'zh-CN': require('./language/zh.js'),
    'en-US': require('./language/en.js'),
    'zh-HK': require('./language/tc.js'),
    'ja-JP': require('./language/ja.js'),
    'ko-KR': require('./language/hw.js'),
  },
  silentTranslationWarn: true
  
  

});
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.use(tool);
Vue.use(Message);
// 路由跳转方法
// Vue.prototype.$Router = {
//   pushPage(obj) {
//     push(obj);
//   },
//   replacePage(obj) {
//     replace(obj);
//   },
//   switchPage(obj) {
//     switchPage(obj);
//   }
// };

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
