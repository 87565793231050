
import lpPriceAbi from '../config/abi/lpPrice.json'
import Web3 from 'web3'
//获取个人信息
function LPPrice(lpAddress,t0price,t1price) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpPriceAbi, '0xdC7C0d5b55BE3117E6A743dA46B968736E07dBe2');
        crossingOkContractInstance.methods.LPPrice(lpAddress,t0price,t1price).call().then(res => {
            resolve(res)
        })
    })
}

export default {
    LPPrice
}

