<template>
  <div class="mine">
    <div class="switch">
      <span @click="selectedClick(0)"
            :class="switchIndex ==0?'selected':''">PHO</span>
      <span @click="selectedClick(1)"
            :class="switchIndex ==1?'selected':''">GDT</span>
      <span @click="selectedClick(2)"
            :class="switchIndex ==2?'selected':''">VGT</span>
    </div>
    <!-- PHO -->
    <div v-show="switchIndex ==0"
         class="pho_box">
      <div class="rating">
        <div class="title">{{ $t('lang.swap317') }}</div>
        <div class="interests">
          <img src="@/assets/img/and_interests.png"
               class="interests_img" />
          <van-rate v-model="star_card_level"
                    :size="18"
                    color="#ffd21e"
                    void-icon="star"
                    void-color="#eee" />
        </div>
        <div class="digital_li">
          <span>{{ $t('lang.swap318') }}TVL</span>
          <div>
            <span class="digital_amount">{{ significantDigits(tvl,2) }}</span>
            <span>USDT</span>
          </div>
        </div>
        <div class="digital_li">
          <span>{{ $t('lang.swap319') }}TVL</span>
          <div>
            <span class="digital_amount">{{ team_tvl }}</span>
            <span>USDT</span>
          </div>
        </div>
        <div class="digital_li">
          <span>{{ $t('lang.swap320') }}pho</span>
          <div>
            <span class="digital_amount">{{ myBalance.toFixed(6) }}</span>
            <span>USDT</span>
          </div>
        </div>
        <div class="digital_li">
          <span>{{ $t('lang.swap174') }}</span>
          <div>
            <span class="digital_amount">{{total_distribution}}</span>
            <span>USDT</span>
          </div>
        </div>
        <div class="digital_li">
          <span>{{ $t('lang.swap175') }}</span>
          <div>
            <span class="digital_amount">0.0</span>
            <span>USDT</span>
          </div>
        </div>
        <div class="pho_balance">
          <img class="logo_img"
               src="@/assets/img/logo.jpg"
               alt />
          <!-- <div class="logo_img">PHO</div> -->
          <div class="balance_right">
            <span>{{ $t('lang.swap307') }}</span>
            <span class="balance">{{usable_str}}</span>
          </div>
        </div>
        <div @click="getReward"
             class="take_out">
          <span>{{ $t('lang.swap177') }}</span>
        </div>
      </div>
      <div class="pho_illustrate">
        <div class="illustrate_title">
          <span>{{ $t('lang.swap193') }}</span>
          <img @click="illustrateClick"
               src="@/assets/img/arrows.png"
               class="arrows_img"
               :style="{ 'transform': illustrateShow?'rotate(180deg)':''}" />
        </div>
        <div v-show="illustrateShow"
             class="illustrate_text">
          <div>{{ $t('lang.swap194') }}</div>
          <div>{{ $t('lang.swap195') }}</div>
          <div>{{ $t('lang.swap196') }}</div>
          <div>{{ $t('lang.swap197') }}</div>
          <div>{{ $t('lang.swap198') }}</div>
          <div>{{ $t('lang.swap199') }}</div>
          <div>{{ $t('lang.swap200') }}</div>
          <div>{{ $t('lang.swap201') }}</div>
        </div>
      </div>
      <div @click="toNav('Community')"
           class="alliance_box">
        <div class="back">
          <span>{{ $t('lang.swap239') }}</span>
          <img src="@/assets/img/back.png"
               class="back_img" />
        </div>
        <img src="@/assets/img/win.png"
             class="win_img" />
      </div>
    </div>

    <!-- GDT -->
    <div v-show="switchIndex ==1">
      <div class="gdt_box">
        <div class="award_box"
             style="border-radius: 30px 30px 0px 0px;">
          <div class="award_li">
            <div class="award_universal">
              <span>VGT{{ $t('lang.swap302') }}</span>
              <span class="award_amount">{{gdtReward.total_reward}}</span>
            </div>
            <div class="award_universal">
              <span>{{ $t('lang.swap243') }}</span>
              <span class="award_amount">{{gdtReward.addition}}</span>
            </div>
          </div>
          <div class="award_li">
            <div class="award_universal">
              <span>{{ $t('lang.swap250') }}</span>
              <span class="award_amount">{{gdtReward.share_reward}}</span>
            </div>
            <div class="award_universal">
              <span>{{ $t('lang.swap243') }}</span>
              <span class="award_amount">{{gdtReward.share_addition}}</span>
            </div>
          </div>
          <div class="award_li">
            <div class="award_universal">
              <span>{{ $t('lang.swap251') }}</span>
              <span class="award_amount">{{gdtReward.trade_reward}}</span>
            </div>
            <div class="award_universal">
              <span>{{ $t('lang.swap243') }}</span>
              <span class="award_amount">{{gdtReward.trade_addition}}</span>
            </div>
          </div>
          <div class="jump">
            <div class="jump_li"
                 @click="toNav('AssetPack')">
              <img src="@/assets/img/asset_pack.png"
                   class="jump_img" />
              <span>GDT{{ $t('lang.swap305') }}></span>
            </div>
            <div class="jump_li"
                 @click="showClick">
              <img src="@/assets/img/business_deal.png"
                   class="jump_img" />
              <span>{{ $t('lang.swap306') }}></span>
            </div>
          </div>
        </div>
        <div class="receive_box">
          <div class="gdt_balance">
            <img class="logo_img"
                 src="@/assets/img/GDT.png"
                 alt />
            <!-- <div class="logo_img">GDT</div> -->
            <div class="balance_rightg">
              <span>{{ $t('lang.swap307') }}</span>
              <span class="balance_g">{{gdtReward.reward}}</span>
            </div>
          </div>
          <div class="button"
               @click="getLianChuangeReward">
            <span>{{ $t('lang.swap66') }}</span>
          </div>
        </div>
        <div class="award_box">
          <div class="award_li">
            <div class="award_universal">
              <span>{{ $t('lang.swap244') }}</span>
              <span class="award_amount">{{white_list_reward}}</span>
            </div>
            <div class="award_universal">
              <span>{{ $t('lang.swap243') }}</span>
              <span class="award_amount">{{white_list_increment}}</span>
            </div>
          </div>
          <div class="award_li">
            <div class="award_universal">
              <span>{{ $t('lang.swap245') }}</span>
              <span class="award_amount">{{star_card_reward}}</span>
            </div>
            <div class="award_universal">
              <span>{{ $t('lang.swap243') }}</span>
              <span class="award_amount">{{star_card_reward_increment}}</span>
            </div>
          </div>
          <div class="award_li"
               style="border-bottom: 0;">
            <div class="award_universal">
              <span>{{ $t('lang.swap246') }}</span>
              <span class="award_amount">0</span>
            </div>
            <div class="award_universal">
              <span>{{ $t('lang.swap243') }}</span>
              <span class="award_amount">0</span>
            </div>
          </div>
        </div>
        <div class="receive_box">
          <div class="gdt_balance">

            <img class="logo_img"
                 src="@/assets/img/GDT.png"
                 alt />
            <div class="balance_rightg">
              <span>{{ $t('lang.swap307') }}</span>
              <span class="balance_g">{{useReward}}</span>
            </div>
          </div>
          <div class="button"
               @click="receiveClick">
            <span>{{ $t('lang.swap66') }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- VGT -->
    <div v-show="switchIndex ==2"
         style="flex: 1;">
      <div class="vgt_box">
        <div class="vgt_li">
          <div class="vgt_universal">
            <span>VGT{{ $t('lang.swap302') }}</span>
            <span class="universal_amount">{{vgtReward.total_reward}}</span>
          </div>
          <div class="vgt_universal">
            <span>{{ $t('lang.swap243') }}</span>
            <span class="universal_amount">{{vgtReward.addition}}</span>
          </div>
        </div>
        <div class="vgt_balance">
          <img class="logo_img"
               src="@/assets/img/VGT.png"
               alt />
          <!-- <div class="logo_img">VGT</div> -->
          <div class="balance_rightv">
            <span>{{ $t('lang.swap307') }}</span>
            <span class="balance_v">{{vgtReward.reward}}</span>
          </div>
        </div>
        <div class="button"
             @click="getLianChuangeVgtReward">
          <span>{{ $t('lang.swap66') }}</span>
        </div>
      </div>
    </div>

    <van-popup v-model="show"
               position="bottom">
      <div class="transaction_box">
        <div class="heading">
          <span>{{ $t('lang.swap308') }}</span>
        </div>
        <div class="hash">
          <van-field v-model="hashValue"
                     class="hash_field"
                     :placeholder="$t('lang.swap252')" />
          <span class="hash_right"
                @click="checkReward">{{ $t('lang.swap253') }}</span>
        </div>

        <div class="heading">
          <span>{{ $t('lang.swap255') }}</span>
        </div>
        <div class="hash">
          <van-field v-model="tcAddress"
                     class="hash_field"
                     :placeholder="$t('lang.swap255')"
                     readonly />
        </div>

        <div class="heading">
          <span>{{ $t('lang.swap114') }}</span>
        </div>
        <div class="hash"
             style="margin-bottom: 20px;">
          <van-field v-model="acquire"
                     readonly
                     class="hash_field"
                     :placeholder="$t('lang.swap114')" />
        </div>
        <div>
          <span>{{ $t('lang.swap257') }}</span>
        </div>

        <div class="button"
             style="margin-top: 40px;"
             @click="dexEntry">
          <span>{{ $t('lang.swap309') }}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { market, coin, dexApi } from '@/api/index';
import { mapState } from 'vuex';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import tvl from '@/methods/tvl.js';
import lpPrice from '@/methods/lpPrice.js';
import { accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing, formatAmount } from '@/utils/format.js';
export default {
  data () {
    return {
      switchIndex: 0, //0:PHO, 1:GDT, 2:VGT
      total_distribution: 0, //权益池已分配总额
      usable_str: 0, //待领取数额
      star_card_level: 0, //星卡等级
      tvl: 0, //个人
      team_tvl: 0, //团队
      myBalance: 0, //钱包中的pho
      rewardCoinInfo: undefined, //pho奖励信息

      gdtReward: {
        reward: 0, //可领取
        addition: 0, //昨日新增
        total_reward: 0, //总奖励
        token_id: 0, //代币ID
        share_reward: 0, //分享收益
        share_addition: 0, //昨日分享收益
        trade_reward: 0, //交易收益
        trade_addition: 0, //昨日交易收益
      },

      vgtReward: {
        reward: 0, //奖励
        total_reward: 0, //总奖励
        addition: 0, //昨日新增
        token_id: 0, //代币ID
      },

      star_card_reward: 0, //星卡奖励
      white_list_reward: 0, //白名单奖励
      useReward: 0, //可领取
      white_list_increment: 0,
      star_card_reward_increment: 0,
      token_id: undefined, //可领取奖励ID

      illustrateShow: true,
      show: false, //弹窗状态
      hashValue: '', //交易哈希
      tcAddress: '', //交易地址
      acquire: 0, //获得
    };
  },
  methods: {
    async init () {
      if (this.account) {
        this.starInfo();
        this.getUserReward();
        this.gdtInfo();
        this.getUserGdtReward();
        this.getGdtRewardInfo();
        const erc20Contract = getErc20Contract('0xC6894a787A41271271662d9553AF627B8A0717B7');
        let bal = await erc20Contract.methods.balanceOf(this.account).call();
        this.myBalance = (bal * 1) / Math.pow(10, 18);
        // this.getPhoPrice();
      }
    },

    toPack () {
      this.$toast(this.$t('lang.swap267'));
    },

    checkReward () {
      if (!this.hashValue) {
        this.$toast(this.$t('lang.swap310'));
        return;
      }
      let data = {
        tx_hash: this.hashValue,
      };
      dexApi.validHash(data, this.account).then(res => {
        if (res.code == 200) {
          this.tcAddress = res.data.address
          this.acquire = res.data.reward_amount * 1
        } else {
          this.$toast(res.msg);
        }
      });
    },
    dexEntry () {
      if (this.acquire == 0) {
        this.$toast(this.$t('lang.swap218'))
        return
      } else {
        let data = {
          tx_hash: this.hashValue,
        };
        dexApi.dexEntry(data, this.account).then(res => {
          this.show = false
          this.$toast(this.$t('lang.swap311'))
          this.init()
        })
      }
    },

    // async getPhoPrice() {
    //   const jackPairContract = getJackPairContract('0x45AE267070B3f98f3719669976Ed4568a6b29e27');
    //   let reserves = await jackPairContract.methods.getReserves().call();
    //   let usdtAddress = '0x382bb369d343125bfb2117af9c149795c6c65c50'; //from
    //   let phoAddress = '0xC6894a787A41271271662d9553AF627B8A07'; //to
    //   if (phoAddress.toLocaleLowerCase() < usdtAddress.toLowerCase()) {
    //     let fromAll = reserves._reserve0 / Math.pow(10, 18);
    //     let toAll = reserves._reserve1 / Math.pow(10, 18);
    //     // console.log('1111');
    //     this.phoPrice = toAll / fromAll; //币的价格
    //   } else {
    //     let fromALL = reserves._reserve1 / Math.pow(10, 18);
    //     let toAll = reserves._reserve0 / Math.pow(10, 18);
    //     //  console.log('2222');
    //     this.phoPrice = toAll / fromALL; //币的价格
    //   }

    //   this.getLpBalance();
    // },
    selectedClick (i) {
      this.switchIndex = i;
    },
    showClick () {
      this.show = true;
    },
    toNav (router) {
      this.$router.push({ name: router });
    },
    illustrateClick () {
      this.illustrateShow = !this.illustrateShow;
    },
    getGdtRewardInfo () {
      coin.rewardInfo(this.account).then(res => {
        if (res.code == 200) {
          this.gdtReward = res.data;
        }
      });
    },
    //联创奖励取出
    getLianChuangeReward () {
      if (this.account == '') return;
      if (this.gdtReward.reward * 1 == 0) {
        this.$toast(this.$t('lang.swap218'));
        return;
      }
      let data = {
        coin_id: this.gdtReward.token_id,
      };
      market.marketRewardOut(data, this.account, this.language).then(res => {
        if (res.code == 200) {
          this.$toast(this.$t('lang.swap67'));
        } else {
          this.$toast(res.msg);
        }
        this.init();
      });
    },
    //获取vgt奖励信息
    getVgtRewardInfo () {
      coin.rewardVgtInfo(this.account).then(res => {
        if (res.code == 200) {
          this.vgtReward = res.data;
        }
      });
    },
    //vgt联创奖励取出
    getLianChuangeVgtReward () {
      if (this.account == '') return;
      if (this.vgtReward.reward * 1 == 0) {
        this.$toast(this.$t('lang.swap218'));
        return;
      }
      let data = {
        coin_id: this.vgtReward.token_id,
      };
      market.marketRewardOut(data, this.account, this.language).then(res => {
        if (res.code == 200) {
          this.$toast(this.$t('lang.swap67'));
        } else {
          this.$toast(res.msg);
        }
        this.init();
      });
    },
    //权益卡信息
    async gdtInfo () {
      this.language = await localStorage.getItem('lang');
      market.gdtInfo(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.star_card_reward = res.data.star_card_reward;
          this.white_list_reward = res.data.white_list_reward;
          this.white_list_increment = res.data.white_list_increment;
          this.star_card_reward_increment = res.data.star_card_reward_increment;
          this.token_id = res.data.token_id;
        }
      });
    },
    //获取gdt奖励信息
    async getUserGdtReward () {
      this.language = await localStorage.getItem('lang');
      market.rewardUserRewards(this.account, this.language).then(res => {
        for (let i of res.data) {
          if (i.coin_id == 6) {
            this.coin_id = i.coin_id;
            this.useReward = i.usable_str;
          }
        }
      });
    },
    //获取gdt奖励
    receiveClick () {
      if (this.account == '') return;
      if (this.useReward * 1 == 0) {
        this.$toast(this.$t('lang.swap218'));
        return;
      }
      let data = {
        coin_id: this.coin_id,
      };
      market.marketRewardOut(data, this.account, this.language).then(res => {
        if (res.code == 200) {
          this.$toast(this.$t('lang.swap67'));
        } else {
          this.$toast(res.msg);
        }
        this.init();
        // console.log(res, '权益池取出');
      });
    },
    //权益卡信息
    async starInfo () {
      this.language = await localStorage.getItem('lang');
      market.marketStarCardInfo(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.cardInfo = res.data;
          this.total_distribution = this.cardInfo.total_distribution;
          this.star_card_level = this.cardInfo.star_card_level;
          this.self_tvl = this.cardInfo.self_tvl;
          this.team_tvl = this.cardInfo.team_tvl;
        }
      });
    },
    //获取个人可领取
    async getUserReward () {
      this.language = await localStorage.getItem('lang');
      market.rewardUserRewards(this.account, this.language).then(res => {
        for (let i of res.data) {
          if (i.coin_id == 3) {
            this.rewardCoinInfo = i;
          }
        }
        this.usable_str = this.rewardCoinInfo.usable_str;
      });
    },
    getLpBalance () {
      tvl.userInfo(this.account).then(res => {
        this.lpAmount = res.amount / Math.pow(10, 18); //获取当前质押lp的数量
        this.getLpPrice();
      });
    },

    getLpPrice () {
      this.phoPrice = this.phoPrice * Math.pow(10, 18) + '';
      let u = 1 * Math.pow(10, 18) + '';
      lpPrice.LPPrice('0x45ae267070b3f98f3719669976ed4568a6b29e27', u, this.phoPrice).then(res => {
        this.lpPrice = res / Math.pow(10, 18);
        this.tvl = accMul(this.lpAmount, this.lpPrice);
        // console.log(this.tvl, '个人的tvl价值');
      });
    },
    // 取出奖励
    getReward () {
      if (this.account == '') return;
      if (this.rewardCoinInfo.usable_str == 0) {
        this.$toast(this.$t('lang.swap218'));
        return;
      }
      let data = {
        coin_id: this.rewardCoinInfo.coin_id,
      };
      market.marketRewardOut(data, this.account, this.language).then(res => {
        if (res.code == 200) {
          this.$toast(this.$t('lang.swap67'));
        } else {
          this.$toast(res.msg);
        }
        this.init();
      });
    },
    significantDigits,
  },
  created () {
    this.init();
    if (this.$route.query.id) {
      this.switchIndex = this.$route.query.id;
    }
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
  mounted () {
    this.pageHeight = (document.documentElement.clientHeight) - 50 - 56;
  }
};
</script>

<style lang="less" scoped>
.mine {
  display: flex;
  flex-direction: column;
  height: 100%;

  // padding: 0 0 30px;
  // padding-bottom: 30px;
  .switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #999999;
    padding: 20px 40px 0;
    span {
      border-bottom: 4px solid #366ef100;
      padding-bottom: 10px;
    }
    .selected {
      font-weight: bold;
      color: var( --text-color);
      border-bottom: 4px solid #ffc300;
    }
  }
  .logo_img {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    background: rgba(204, 204, 204, 1);
    border-radius: 50%;
  }
  .pho_box {
    .rating {
      color: #000;
      border-radius: 30px 30px 0px 0px;
      background: var( --text-color);
      padding: 30px 20px;
      .title {
        font-size: 18px;
        font-weight: bold;
        color: var( --text-color);
      }
      .interests {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 0;
        .interests_img {
          display: block;
          width: 160px;
          height: 106px;
          margin-bottom: 10px;
        }
      }
      .digital_li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        color: var( --text-color);
        margin-bottom: 20px;
        .digital_amount {
          font-size: 18px;
          color: #ffc300;
          margin-right: 4px;
        }
      }
      .pho_balance {
        display: flex;
        align-items: center;
        .balance_right {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: bold;
          color: var( --text-color)9c;
          margin-left: 10px;
          .balance {
            color: #00baad;
            // background-image: -webkit-linear-gradient(bottom, red, rgba(56, 109, 241, 1), rgba(156, 64, 189, 1), rgba(250, 128, 19, 1));
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            margin-top: 10px;
          }
        }
      }
      .take_out {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        color: var( --text-color);
        border-radius: 12px;
        background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
        padding: 16px 0;
        margin-top: 20px;
      }
    }
    .pho_illustrate {
      background: var( --text-color);
      padding: 20px;
      margin-top: 6px;
      .illustrate_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        font-weight: bold;
        color: var( --text-color);
        .arrows_img {
          display: block;
          width: 10px;
          height: 6px;
        }
      }
      .illustrate_text {
        font-size: 12px;
        font-weight: bold;
        color: var( --text-color)96;
        div {
          margin-top: 20px;
        }
      }
    }
    .alliance_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var( --text-color);
      padding: 20px;
      margin-top: 6px;
      .back {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: var( --text-color);
        .back_img {
          display: block;
          width: 20px;
          height: 20px;
          transform: rotate(180deg);
          margin-left: 6px;
        }
      }
      .win_img {
        display: block;
        width: 104px;
        height: 66rpx;
      }
    }
  }
  .gdt_box {
    .receive_box {
      background: var( --text-color);
      padding: 20px;
      margin-bottom: 6px;
      .gdt_balance {
        display: flex;
        align-items: center;
        .balance_rightg {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: bold;
          color: var( --text-color)96;
          margin-left: 10px;
          .balance_g {
            color: #00baad;
            // background-image: -webkit-linear-gradient(bottom, red, rgba(56, 109, 241, 1), rgba(156, 64, 189, 1), rgba(250, 128, 19, 1));
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            margin-top: 10px;
          }
        }
      }
    }
    .award_box {
      color: var( --text-color);

      background: var( --text-color);
      padding: 20px 20px;
      margin-bottom: 6px;
      .award_li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #323944;
        padding: 12px 0;
        .award_universal {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: bold;
          color: var( --text-color)96;
          .award_amount {
            color: var( --text-color);
            margin-top: 6px;
          }
        }
      }
      .jump {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .jump_li {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: var( --text-color);
          .jump_img {
            display: block;
            width: 80px;
            height: 80px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  .vgt_box {
    box-sizing: border-box;
    height: 100%;
    color: var( --text-color);
    border-radius: 30px 30px 0px 0px;
    background: var( --text-color);
    padding: 30px 20px;
    .vgt_li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .vgt_universal {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: bold;
        color: var( --text-color)98;
        .universal_amount {
          color: var( --text-color);
          margin-top: 6px;
        }
      }
    }
    .vgt_balance {
      display: flex;
      align-items: center;
      margin-top: 30px;
      .balance_rightv {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: bold;
        color: var( --text-color)9c;
        margin-left: 10px;
        .balance_v {
          color: #00baad;
          // background-image: -webkit-linear-gradient(bottom, red, rgba(56, 109, 241, 1), rgba(156, 64, 189, 1), rgba(250, 128, 19, 1));
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          margin-top: 10px;
        }
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: var( --text-color);
    border-radius: 12px;
    background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
    padding: 16px 0;
    margin-top: 20px;
  }

  .transaction_box {
    background: var( --text-color);
    font-size: 14px;
    font-weight: bold;
    color: var( --text-color);
    padding: 20px;
    .heading {
      font-size: 16px;
      font-weight: bold;
      color: var( --text-color);
    }
    .hash {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      font-weight: normal;
      color: #999999;
      border: 1px solid #2d343f;
      padding: 10px 10px;
      margin: 20px 0 30px;
      .hash_field {
        background: #00000000;
        /deep/ .van-field__control {
          color: var( --text-color)a4;
        }
      }
      .hash_right {
        width: 20%;
        font-size: 14px;
        font-weight: bold;
        color: #ffc300;
        border-left: 1px solid #2d343f;
        padding-left: 16px;
        margin-left: 10px;
      }
    }
  }
}
</style>