<template>
  <div class="zhuzao">
    <topbutton router="snowguan" style="margin-bottom: 15px;"></topbutton>
    <div class="bgzhuzao">
      <div class="b_box">
        <span class="b_money">{{ rewarded }}</span>
        <span class="b_text">{{ $t('lang.d50') }}</span>
      </div>
      <div class="b_box">
        <span class="b_money">{{ remain }}</span>
        <span class="b_text">{{ $t('lang.d51') }}</span>
      </div>
    </div>
    <div class="shanshao">
      <div class="ssttitle">
        <p class="s_title">{{ $t('lang.d52') }}</p>
        <div class="check">
          <van-switch  active-color="#4580FF" inactive-color="#0D0C0F" size="25" @click="checkedfun"/>
          <van-icon name="question-o" size="30" @click="clickshowtext" />
        </div>
      </div>
     
      <div class="s_line1">
        <van-field type="number"
                           v-model="toInput"
                           input-align="left"
                           
                           :placeholder="`${$t('lang.d53')}`"
                           class="van-field" />
                           <span>USDT</span>
      </div>
      <p class="s_line2">
        {{ $t('lang.d30') }} {{ banceof }}
      </p>
      <div class="s_buttonz" v-if="!toInput">{{ $t('lang.d8') }}</div>
      <div class="s_button" v-else @click="zhuzao">{{ $t('lang.d8') }}</div>
      
    </div>
    <van-tabs v-model="active" animated class="van_tabs">
  <van-tab   :title="`${$t('lang.d42')}`">
    <div class="v_box">
      <div class="c_wlist" v-if="zhiyoulist.length == 0">
      <img src="@/assets/imgsnowball/wujilu.png" alt="">
      <p>{{ $t('lang.d57') }}</p>
   </div>
      <div class="li_line" v-for="item in zhiyoulist" v-else>
       <img src="@/assets/imgsnowball/zhuzaonft.png" alt="">
       <div class="l_cont">
        <div class="ranshao">
          <span class="span">{{ $t('lang.d52') }} {{item.pay_amount}}USDT</span>
          <span class="timer"> {{ item.created_time }}</span>
         
        </div>
        <div class="liuton">
          <span>{{ $t('lang.d56') }}</span>
          <div class="propess">
            <div class="banfen" style="text-align: right; color: #1ff0ffff; font-size: 12pxx;"> {{(item.released_amount/item.release_amount*100).toFixed(2)}}%</div>
            <van-progress
           
          :show-pivot ="false"
  :percentage="item.released_amount/item.release_amount*100"
  track-color ="#272A2C"
  pivot-color="#272A2C"
  color="#1ff0ffff"
     
/>
          </div>
         
        </div>
       </div>
    </div>
   
  
    </div>
   
  </van-tab>
  <van-tab   :title="`${$t('lang.d54')}`">
    <div class="c_wlist" v-if="jinxinlist.length == 0">
      <img src="@/assets/imgsnowball/wujilu.png" alt="">
      <p>{{ $t('lang.d57') }}</p>
   </div>
   <div class="li_line" v-for="item in jinxinlist" v-else>
       <img src="@/assets/imgsnowball/zhuzaonft.png" alt="">
       <div class="l_cont">
        <div class="ranshao">
            <span class="span">{{ $t('lang.d52') }} {{item.pay_amount}}USDT</span>
          <span class="timer"> {{ item.created_time }}</span>
         
        </div>
        <div class="liuton">
          <span>{{ $t('lang.d56') }}</span>
          <div class="propess">
            <div class="banfen" style="text-align: right; color: #1ff0ffff; font-size: 12pxx;"> {{(item.released_amount/item.release_amount*100).toFixed(2)}}%</div>
            <van-progress
          :show-pivot ="false"
  :percentage="item.released_amount/item.release_amount*100"
  track-color ="#272A2C"
  pivot-color="#272A2C"
  color="#1ff0ffff"
     
/>
          </div>
         
        </div>
       </div>
    </div>
  </van-tab>
  <van-tab  :title="`${$t('lang.d55')}`">
    <div class="c_wlist" v-if="jieshulist.length == 0">
      <img src="@/assets/imgsnowball/wujilu.png" alt="">
      <p>{{ $t('lang.d57') }}</p>
   </div>
    <div class="li_line" v-for="item in jieshulist" v-else>
       <img src="@/assets/imgsnowball/zhuzaonft.png" alt="">
       <div class="l_cont">
        <div class="ranshao">
          <span class="span">{{ $t('lang.d52') }} {{item.pay_amount}}USDT</span>
          <span class="timer"> {{ item.created_time }}</span>
         
        </div>
        <div class="liuton">
          <span>{{ $t('lang.d56') }}</span>
          <div class="propess">
            <div class="banfen" style="text-align: right; color: #1ff0ffff; font-size: 12pxx;"> {{(item.released_amount/item.release_amount*100).toFixed(2)}}%</div>
            <van-progress
          :show-pivot ="false"
  :percentage="item.released_amount/item.release_amount*100"
  track-color ="#272A2C"
  pivot-color="#272A2C"
  color="#1ff0ffff"
     
/>
          </div>
         
        </div>
       </div>
    </div>
  </van-tab>
</van-tabs>
  </div> 
</template>

<script>
import Web3 from 'web3';
import { mapState } from 'vuex';
import topbutton from '@/components/topbutton.vue';
import { zhubi,moneymask,user } from '@/api/index';
export default {
  name: '',
  components: {
    topbutton
  },
  data() {
    return {
      active:"",
      checked:false,
      toInput:"",
      selectlist:[
        {
            name:"全部"
        },
        {
            name:"进行中"
        },
        {
            name:"已结束"
        },
       
      ],
      canbuy:0,
      banceof:0.00,
      rewarded:0.00,
      remain:0.00,
      zhiyoulist:[],
      jinxinlist:[],
      jieshulist:[],
      parent_id:0
    }
  },
  computed: {
    ...mapState(['account','lang']),
   
  },
  created() {
     this.init()
  },
  watch: {
    account () {
      //获取账号
      this.init();

    },
   
    lang () {
      this.init();
    }
  },
  methods: {
    init (){
      this.getdata()
      this.getchiyou()
      this.gettuandui()
      this.gettuanxing()
      this.getucinfo()
    },
       async zhuzao (){
          if(this.toInput<=this.canbuy){
              if(this.parent_id == 0){
                this.$toast(this.$t('lang.d94'))
              }else{
                this.sendzhu();
              }
          }else{
            this.$toast(`${this.$t('lang.d95')}${this.canbuy}`)
          }
         
        },
        clickshowtext (){
           this.$toast(this.$t('lang.d109'))
        },
      async sendzhu (){
          let web3 = new Web3(window.ethereum);
            const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Mint'), this.account)
            console.log("sign",sign);
            let data = {
            amount:parseFloat(this.toInput),
            signature:sign
          }
        await  zhubi.postzhubi(data,this.account).then(res =>{
            console.log(res);
            this.$toast(res.msg)
            this.init()
          })
        },
        checkedfun (){
      this.$toast(this.$t('lang.swap65'))
    },
        getdata (){
           zhubi.getpeizhi(this.account).then(res =>{
            console.log(res);
           
            this.canbuy = res.data.can_buy
            console.log(this.canbuy);
           })
           let data ={
            token_id:1
           };
           moneymask.getwalletgetinfo(data,this.account).then(res =>{
            this.banceof =res.data.usable
           })
           zhubi.getshoyixinxi(this.account).then(res =>{
               this.remain =res .data.remain
               this.rewarded =res.data.rewarded
           })
        },
        getucinfo (){
          user.getucinfo(this.account).then(res =>{
                  console.log("w",res);
                 
                  this.parent_id=res.data.parent_id
           })
          },
        getchiyou (){
          zhubi.getproductholdlist(this.account).then(res =>{
            console.log(res);
            this.zhiyoulist =res.data.list
            this.jinxinlist =this.zhiyoulist.map((item,index,arr)=>{
                   if(item.status==1){
                    return item
                   }else{
                    return '';
                   }
            })
          this.jinxinlist =this.getarraytrim(this.jinxinlist)
            this.jieshulist =this.zhiyoulist.map((item,index,arr)=>{
                   if(item.status==0){
                    return item
                   }else{
                    return '';
                   }
            })
            this.jieshulist =this.getarraytrim(this.jieshulist)
            console.log( this.zhiyoulist);
            console.log(this.jinxinlist );
            console.log(this.jieshulist);
          })
        },
        getarraytrim (array){
         var arr =[]
     for(var i = 0 ;i<array.length;i++)  
     {  
         if(array[i] != "")  
         {  
               arr.push(array[i])

         }  
     }  
     console.log(arr);
     return arr;  

        },
        gettuandui (){
          zhubi.getproductteamlist(this.account).then(res =>{
            console.log(res);
          })
        },
        gettuanxing (){
          zhubi.gettuandui(this.account).then(res =>{
            console.log(res);
          })
        },
     
  },
}
</script>

<style lang="less" scoped>
.zhuzao{
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 0;
 .bgzhuzao{
  margin-bottom: 20px;
  width: 100%;
  height: 115px;
  background: url("~@/assets/imgsnowball/bgzhuzao.png") no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  div{
    width: 50%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .b_money{
      margin-bottom: 10px;
      color: var( --text-color);
 font-size: 24px;
 font-weight: 500;
 font-family: "HarmonyOSSansSC";
    }
    .b_text{
      color: var( --text-color);
 font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";
    }
  }
 }
 .shanshao{
  margin-bottom: 30px;
  width: 100%;
height: 269px;
border-radius: 20px;
padding: 15px;
box-sizing: border-box;
background: #292a2eff;
.s_title{
  color: var( --text-color);
 font-size: 16px;
 font-weight: 700;
 font-family: "PingFang";
}
.s_line1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span{
      color: var( --text-color);
 font-size: 16px;
 font-weight: 700;
 font-family: "PingFang";
    }
}
.s_line2{
  color: #9395a4ff;
 font-size: 12px;
 font-weight: 700;
 font-family: "PingFang";
}
.s_button{
  width: 100%;
  margin-top: 40px;
  text-align: center;
  line-height: 55px;
height: 55px;
border-radius: 10px;
opacity: 1;
color: var( --text-color);
 font-size: 16px;
 font-weight: 700;
 font-family: "PingFang";
background: linear-gradient(160deg, #06e2e1ff 0%, #32a0ffff 75%);
box-shadow: 0 20px 30px 0 #191c321a;
}
.s_buttonz{
  width: 100%;
  margin-top: 40px;
  text-align: center;
  line-height: 55px;
height: 55px;
border-radius: 10px;
opacity: 1;
color: #4B4B4D;
 font-size: 16px;
 font-weight: 700;
 font-family: "PingFang";
background: #313235;
box-shadow: 0 20px 30px 0 #191c321a;
}
 }
}
.van-field {
        width: 233px;
        border-radius: 10px;
        font-weight: bold;
        background: #313235ff !important;
        padding: 16px 20px !important;
        /deep/ .van-field__control {
          color: #9395a4ff;
 font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";
        }
      }
      .van_tabs{
    background: #0D0C0F;
    
}
/deep/.van-tabs__nav {
    background: #0D0C0F !important;
}
/deep/.van-tabs__line{
    background: #369BFA !important;
    width: 20px;
    height: 2px;
}
/deep/.van-tab {
    
    color: #999999ff;
    font-size: 16px;
    font-weight: 700;
    font-family: "PingFang";
   }
/deep/.van-tab--active{
    color: var( --text-color);
    font-size: 18px;
 font-weight: 700;
 font-family: "PingFang";
}
.li_line{
  padding: 15px 17px;
  width: 335px;
height: 136px;
border-radius: 20px;
display: flex;
background: #292a2eff;
margin-top: 10px;
img{
  // width: 103px;
  height: 100%;
}
.l_cont{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  div{
    display: flex;
    flex-direction: column;
   
  }
}
}
.ranshao{
  display: flex;
  flex-direction: column;
  .span{
    color: var( --text-color);
 font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";
  }
  .timer{
    color: #999999ff;
 font-size: 10px;
 font-weight: 500;
 font-family: "PingFang";
  }
}
/deep/.van-progress{
  border: 1px solid #1ff0ffff;
  width: 182px;
  height: 8px;
}
.liuton{
  display: flex;
  height: 50%;
  justify-content: space-between;
  span{
    color: var( --text-color);
 font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";

  }
}
.v_box{
  max-height: 450px;
  overflow: scroll;
}
.c_wlist{
  margin-top: 10px;
    width: 100%;
height: 219px;
border-radius: 20px;
opacity: 1;
background: #292a2eff;
padding: 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
img{
    width: 200px;
  
}
p{
    color: var( --text-color);
 font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";
}
}
.ssttitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .check{
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: space-between;
  }
}
//  /deep/.van-switch__node{
//   background: #737373;
//  }       
</style>