export const lang = {
    swap1: '閃光',
    swap2: 'すぐに点滅を開始します',
    swap3: 'from',
    swap4: 'balance: ',
    swap5: '通貨を選択',
    swap6: 'max',
    swap7: '価格',
    swap8: '滑り許容値',
    swap9: 'ウォレットを接続する',
    swap10: '数量を入力してください',
    swap11: '残高不足',
    swap12: '取引の流動性が十分ではない',
    swap13: 'トークンを選択',
    swap14: '認可された',
    swap15: 'パッケージ',
    swap16: '開梱する',
    swap17: '閃光',
    swap18: '取引の領収書',
    swap19: '確認を待ちます',
    swap20: '動いている',
    swap21: 'と交換されました',
    swap22: 'ウォレットでこの取引を確認してください',
    swap23: 'トランザクションが送信されました',
    swap24: 'BSCで見る',
    swap25: '閉鎖',
    swap26: 'トランザクションが拒否されました',
    swap27: '交換の確認',
    swap28: '出力は推定されます。少なくとも {inputOutMin} {symbol} を取得するか、トランザクションは取り消されます。',
    swap29: '評価額として入力すると、{inputInMax} {symbol} まで売却するか、取引が撤回されます。',
    swap30: '最低取得金額',
    swap31: '最大売上高',
    swap32: 'to',
    swap33: 'トークンの管理',
    swap34: 'インポートトークン',
    swap35: '輸入',
    swap36: 'トークンの管理',
    swap37: 'カスタムトークン',
    swap38: 'すべてクリア',
    swap39: '名前を検索するか、住所を貼り付けます',
    swap40: '設定',
    swap41: 'デフォルトのトランザクション速度 (GWEI)',
    swap42: '標準',
    swap43: '速い',
    swap44: 'すぐに',
    swap46: '有効なスリッページ率を入力してください',
    swap47: '取引が失敗する可能性があります',
    swap48: 'あなたの取引は前倒しされる可能性があります',
    swap49: 'トランザクションの締め切り時間 (分)',
    swap50: '見積もり',
    swap51: 'マルチホップを無効にする',
    swap52: '最近の取引',
    swap53: '最近の取引はありません',
    swap54: 'すべてクリア',
    swap55: 'リンクウォレット',
    swap56: '理事会に参加する',
    swap57: '捐赠',
    swap58: '权益赠送',
    swap59: '董事会成员列表',
    swap60: '招待',
    swap61: 'コピー',
    swap62: '我的DAPP邀请人:',
    swap63: '邀请记录',
    swap64: '出错啦，请稍后再试',
    swap65: 'この機能はまだ利用できません',
    swap66: '领取',
    swap67: '取出成功',
    swap68: '捐赠中，待确认',
    swap69: '已捐赠',
    swap70: '非白名单用户',
    swap71: '捐赠无效',
    swap72: '当前余额不足',
    swap73: '授权中，请稍等',
    swap74: '授权失败',
    swap75: '捐赠中，请稍等',
    swap76: '捐赠失败',
    swap77: '交換',
    swap78: '流動性',
    swap79: 'メンバー',
    swap80: '招待',
    swap81: '株式プール',
    swap82: 'クロスチェーンブリッジ',
    swap83: '池',
    swap84: '農場',
    swap85: '公式ウェブサイト',
    swap86: '市場',
    swap87: '交換',
    swap88: 'トレード',
    swap89: '稼ぐ',
    swap90: '確認',
    swap91: '価格への影響',
    swap92: '最低取得金額',
    swap93: '流動性プロバイダー手数料',
    swap94: '流動性を高める',
    swap95: '流動性を高めてLPトークンを受け取る',
    swap96: '流動性プール内の株式',
    swap97: '為替レートと流動性プールシェア',
    swap98: 'あなたの流動性',
    swap99: '流動性を除去してトークンを回収する',
    swap100: '流動性が見つかりません',
    swap101: '参加したフロー プールが表示されませんか?',
    swap102: '他のLPトークンを見る',
    swap103: 'プールに入った',
    swap104: '削除',
    swap105: '供給',
    swap106: 'インポートプール',
    swap107: '既存の流動性プールをインポートする',
    swap108: 'コインを選択して流動性を確認します。',
    swap109: 'この流動性プールに流動性を提供していません。',
    swap110: 'ウォレット内のLPトークン',
    swap111: '買収',
    swap112: 'と',
    swap113: '総額',
    swap114: 'あなたは得るでしょう',
    swap115: '出力は推定値です。価格が {slippage}% を超えて変化した場合、取引は取り消されます',
    swap116: 'やけど',
    swap117: '入金済み',
    swap118: '供給を確認する',
    swap119: '為替レート',
    swap120: 'あなたは最初の流動性プロバイダーです。',
    swap121: '追加するトークンの比率によって、流動性プールの初期価格が決まります。',
    swap122: '為替レートに満足したら、"供給"をクリックして確認してください。',
    swap123: '削除する',
    swap124: '供給する',
    swap125: '供給を確認する',
    swap126: '白書',
    swap127: '監査',
    swap128: '発表',
    swap129: 'ヒント',
    swap130: 'PHO 1:1 交換',
    swap131: 'もっと',
    swap132: '読み込み中',
    swap133: '情報',
    swap134: '寄付枠は満杯です',
    swap135: '私のフォー',
    swap136: '財布の中のPHO',
    swap137: '収穫可能なPHO',
    swap138: 'PHO价格',
    swap139: 'PHO总量',
    swap140: 'PHO市值',
    swap141: 'PHO信息',
    swap142: '已赚取',
    swap143: '质押',
    swap144: '个人TVL',
    swap145: '社区TVL',
    swap146: '收割',
    swap147: '累计激励 ',
    swap148: '今日激励',
    swap149: '参与市商地址数',
    swap150: '我的邀请链接',
    swap151: '最大算力区',
    swap152: '其他算力区',
    swap153: '我的好友邀请',
    swap154: 'キャンセル',
    swap155: '修改邀请人',
    swap156: '邀请人不能为空',
    swap157: '修改成功',
    swap158: '质押LP代币',
    swap159: '赎回LP代币',
    swap160: '赎回',
    swap161: '获取',
    swap162: '当前暂无收益',
    swap163: 'ルーティング',
    swap164: '監査役',
    swap165: '相棒',
    swap166: '发行',
    swap167: '活动',
    swap168: '单币池',
    swap169: 'LP池',
    swap170: '国会',
    swap171: '卡牌',
    swap172: '盲盒',
    swap173: '游戏',
    swap174: '权益池已分配总额',
    swap175: '待领取数额',
    swap176: '我已获取数额',
    swap177: '取出',
    swap178: '超级节点',
    swap179: '权益卡已领取份额',
    swap180: '国库',
    swap181: '国库累计金额',
    swap182: '国库已分配金额',
    swap183: '国库已回购金额',
    swap184: 'エキスパートモード',
    swap185: 'D6 Swap是基于OKExChain研发的全网首个股权税收制Defi平台，逐步推出Swap、NFT、Gamefi、Lend、机枪池等板块。面世以来通过领先的技术和公平公开的市场理念，提供更加广泛的资产增值方案和前所未有的体验感，深受广大用户喜爱。',
    swap186: '一键取出',
    swap187: '价格影响过高',
    swap188: 'FAQ',
    swap189: 'NFT',
    swap190: '赚币',
    swap191: '进行中',
    swap192: '已结束',
  
    swap193: '说明',
    swap194: '股权权益生态卡，全球限量1680张',
    swap195: '点亮股权权益生态卡的要求及收益如下',
    swap196: '一星卡：个人地址拥有价值1000USDT流动性，团队1000USDT流动性，收益共分交易税收0.1%',
    swap197: '二星卡：个人拥有价值2000USDT流动性，团队1万USDT流动性，收益共分交易税收0.1%',
    swap198: '三星卡：个人3000USDT流动性，团队10万USDT流动性，设立工作室，收益共分交易税收0.1%',
    swap199: '四星卡：个人拥有价值4000USDT流动性,团队100万USDT流动性,设立工作室，收益共分交易税收0.1%',
    swap200: '五星卡：个人5000USDT流动性，团队1000万USDT流动性，设立工作室，收益共分交易税收0.1%',
    swap201: '备注：所有权益生态卡收益全部链上自动分配，权益卡享受交易所所有生态分红，权益卡可传承可交易可转让，满足要求自动升级，不满足要求自动降级及自动退出。',
    swap202: '权益卡',
    swap203: '发展路线图：',
    swap204: '2022年7月  2.0版上线',
    swap205: '2022年9月  开通持币多挖',
    swap206: '2022年12月 NFT交易平台上线',
    swap207: '2023年 6月  Goodbaby游戏上线',
    swap208: '2023年 9月  Goodlend上线',
    swap209: 'D6 Swap介绍：',
    swap210: '滑点：',
    swap211: '分配：',
    swap212: '买入',
    swap213: '卖出',
    swap214: '0.5% LP分红',
    swap215: '0.5% 生态权益卡分红',
    swap216: '0.25% 回流底池销毁LP',
    swap217: '0.25% 生态建设',
    swap218: '暂无奖励领取',
    swap219: '个人TVL',
    swap220: '团队TVL',
    swap221: 'LP数量',
    swap222: '钱包中的pho',
    swap223: 'GDT',
    swap224: 'GDT空投累计奖励',
    swap225: '星级用户累计奖励',
    swap226: '免手续费倒计时',
    swap227: '可取出奖励',
    swap228: 'LP挖矿奖励',
    swap229: '质押pho',
    swap230: '赎回pho',
    swap231: '目标共识',
    swap232: '已达共识',
    swap233: '已共识',
    swap234: '共识',
    swap235: '已解锁',
    swap236: '可领取',
    swap237: '社区治理',
    swap238: '联盟共识',
    swap239: '社区联盟',
    swap240: '质押代币',
    swap241: '质押中，请稍等',
    swap242: '交易挖矿',
    swap243: '昨日新增',
    swap244: '社区白名单累计奖励',
    swap245: '股权星卡累计奖励',
    swap246: 'LP挖矿累计奖励',
    swap247: '当前可领取',
    swap248: '当前可领取金额',
    swap249: '交易挖矿',
    swap250: '我的分享奖励累计',
    swap251: '我的交易奖励累计',
    swap252: '请输入您的交易哈希',
    swap253: '校验',
    swap254: '请输入您的哈希',
    swap255: '您的交易地址',
    swap256: '您将获得',
    swap257: '仅买入GDT及创建流动性的交易可获得奖励',
    swap258: '质押GDT-PHO代币',
    swap259: '赎回GDT-PHO代币',
    swap260: '全网手续费累计',
    swap261: '待领取分红',
    swap262: 'GDT联合股东',
    swap263: '社交',
    swap264: '商城',
    swap265: '精选好物',
    swap266: '优选小店',
    swap267: '即将上线',
    swap268: 'VGT联创股东',
    swap269: '质押VGT-PHO代币',
    swap270: '赎回VGT-PHO代币',
    swap271: '推荐链接',
    swap272: '请绑定领导人',
  
    swap273: '詳しい',
    swap274: 'メタバース',
    swap275: 'グローバル',
    swap276: '為替と流動性',
    swap277: '取引し、開発し、お金を稼ぎ、',
    swap278: 'マルチチェーンアグリゲーションDEXエコロジープラットフォーム',
    swap279: '最低の取引手数料、有益な機能、複数タイプの紹介プログラムをお楽しみください',
    swap280: '路線図',
    swap281: '2022 年 7 月 バージョン 2.0 がリリースされます',
    swap282: '2022年9月にコイン保有とマルチマイニングがオープン予定',
    swap283: '2022年12月にNFT取引プラットフォームを開始',
    swap284: '2023 年 6 月に Goodbaby ゲームが開始されます',
    swap285: '2023 年 9 月 Goodlend を開始します',
    swap286: 'もっと詳しく知る',
    swap287: 'あなたが持っている',
    swap288: '存在',
    swap289: '暗号化されたデジタル通貨を取引する',
    swap290: '流動性とトークンのステーキング',
    swap291: 'ボーナス報酬を獲得する',
    swap292: '発射台',
    swap293: '認証済み',
    swap294: '高品質の暗号化されたデジタル資産',
    swap295: '学び',
    swap296: 'アートワーク',
    swap297: '市場',
    swap298: 'ブラウズ',
    swap299: 'インスタントトークン交換',
    swap300: 'コピーに成功しました',
    swap301: 'コピーはサポートされていません。このブラウザは自動コピーをサポートしていません',
    swap302: '連荘の累計報酬',
    swap303: '累計共有報酬',
    swap304: '累計取引報酬',
    swap305: 'アセットパック',
    swap306: '取引してコインを獲得',
    swap307: '引き出し可能な残高',
    swap308: 'トランザクションハッシュを入力してください',
    swap309: 'もちろん',
    swap310:'ハッシュ値を入力してください',
    swap311:'正常に登録されました',
  
    swap312:'バイナンス',
    swap313:'平台',
    swap314:'PHO专区',
    swap315:'GDT专区',
    swap316:'VGT专区',
  swap317:'PHO星级卡',
  swap318:'个人',
  swap319:'团队',
  swap320:'钱包中的',
  swap321:'选择资产包',
  swap322:'请选择',
  swap323:'日收益率',
  swap324:'收益比例',
  swap325:'到期总数',
  swap326:'购买',
  swap327:'您的钱包余额',
  swap328:'我的资产包',
  swap329:'今日总收益',
  swap330:'今日の固定収入',
  swap331:'今日のダイナミックな収益',
  swap332:'累計収入',
  swap333:'期限のある数量',
  swap334:'選ぶ',
  swap335:'クリプトゾーン',
  swap336:'購入が成功しました',
  swap337:'DApp を入力してください',
  swap338:'丸',
  swap339:'暗号通貨サークルの仲間とチャットする',
  swap340:'キャンディボックス',
  swap341:'さまざまな高品質アセットのエアドロップ',
  swap342:'無料で入手しに来てください',
  swap343:'クラウドイノベーションプール',
  swap344:'ミントセンター',
  swap345:'コミュニティサークル',
  swap346:'キャンディボックス',
  swap347:'コインを稼ぐ',
  swap348:'道具',
  swap349:'共有センター',
  swap350:'c共有センター',
  swap351:"社会的接触",
  swap352:"橋",
  swap353:"地図",
  swap354:"Water マイニング プール",
  swap355:"マイニングプールの建設に参加し、高額なインセンティブを自由に獲得しましょう",
  swap356:"流動性マイニングプール",
  swap357:"単一通貨のプレッジプール",
  swap358:"リアルタイム",
  swap359:"完成した",
  swap360:"杭打ちのみ",
  swap361:"並び替え",
  swap362:"検索",
  swap363:" 人気 ",
  swap364:"検索プール",
  swap365:"ここには何もありません！",
  swap366:"最初の Water Swap のスタートアップ テンプレート",
  swap367:"それぞれの高品質の投資家と暗号化された資産に最適なパスを見つけます ",
  swap368:"ISOを作成する",
  swap369:"未来の暗号通貨スターになる",
  swap370:"近日公開",
  swap371:"まず推薦者をバインドしてください",
  swap372:"私の招待者",
  swap373:"招待者の住所:",
  swap374:"",
swap375:"第3四半期には、D6 Swapが立ち上げられ、Genesisコインが立ち上げられ、同時に暗号化ソーシャルプラットフォームCIRCLEが導入され、コミュニティの生態学的発展を深く結び付けました。",
swap376:"Q4 D6に流動性を注入し、D6コンセンサスプランを開始し、D6循環コンセンサスを作成し、流動性インセンティブプランを発動します。チェーンアライアンスの国境を越えたモールを立ち上げ、消費付加価値エコロジーを創造し、コミュニティとビジネスの組み合わせの新たな方向を推進します。",
swap377:"",
swap378:"Q1 基本的な流動性サポートとしてD6を推進し、市場での認知度と比率を高め、D-Payを開始し、D6コインの現物取引の流通を促進するための提携サポート計画のパッケージ",
swap379:"第 2 四半期には D6 Trade が正式に開始され、CEX と DEX のデュアル取引プラットフォームの時代が始まり、D6 市場の取引流動性がさらに向上し、新しいパートナーが導入されました。",
  swap380:"",
  swap381:"",
  
  d1:"デポジット",
  d2:"コインを引き出す",
  d3:"移行",
  d4:"明細書",
  d5:"コミュニティ",
  d6:"閃光",
  d7:"フィードバック",
  d8:"鋳造",
  d9:"D6コインを獲得する",
  d10:"までの特典",
  d11:"入力",
  d12:"Waterマーケット",
  d13:"今日の価格",
  d14:"今日の収益",
  d15:"チェック",
  d16:"資産",
  d17:"資産ゼロを隠す",
  d18:"通貨",
  d19:"氷結",
  d20:"利用可能",
  d21:"トークンを選択",
  d22:"チャージ金額",
  d23:"チャージ金額を入力してください",
  d24:"ウォレット残高:",
  d25:"アカウントアドレス",
  d26:"もちろん",
  d27:"リチャージ記録",
  d28:"出金額",
  d29:"出金数量を入力してください",
  d30:"勘定残高：",
  d31:"到着住所",
  d32:"出金記録",
  d33:"払込金額",
  d34:"送金額を入力してください",
  d35:"相手のアドレスを入力",
  d36:"受取人の住所を入力してください",
  d37:"転送記録",
  d38:"資産交換",
  d39:"支払う",
  d40:"得る",
  d41:"量",
  d42:"全て",
  d43:"最大で引き換え可能:",
  d44:"取引税:",
  d45:"アカウントによる:",
  d46:"支払い数量:",
  d47:"フラッシュレコード",
  d48:"交換の確認",
  d49:"フラッシュレコード",
  d50:"獲得した",
  d51:"保留中の収入",
  d52:"燃焼量",
  d53:"燃焼量を入力してください",
  d54:"進行中",
  d55:"以上",
  d56:"循環の進行状況",
  d57:"記録なし！",
  d58:"過去の収益",
  d59:"私の学年",
  d60:"現在のレベル",
  d61:"登録後、D0 ユーザーになることができます",
  d62:"友達招待プログラム",
  d63:"友達を招待して一緒にコインを鋳造し、Water コンセンサス革命を開始して、より早く報酬を獲得しましょう!",
  d64:"リンクをコピーする",
  d65:"私の推薦者",
  d66:"私の有効な推奨事項",
  d67:"住所",
  d68:"レベル",
  d69:"成果",
  d70:"次のレベル：",
  d71:"市場パフォーマンス",
  d72:"まだ終わってない",
  d73:"マーケティング部",
  d74:"あなたはすでに最高レベルにいます",
  d75:"発表",
  d76:"結合確認",
  d77:"リーダーのアドレス:",
  d78:"バインディングを確認する",
  d79:"負またはゼロは指定できません",
  d80:"残高不足",
  d81:"入金成功",
  d82:"入金に失敗しました",
  d83:"手数料：",
  d84:"転入",
  d85:"転出",
  d86:"請求記録",
  d87:"時間",
  d88:"タイプ",
  d89:"量",
  d90:"通貨",
  d91:"最初に支払いトークンを選択してください",
  d92:"このトークンは双方向の交換を禁止します",
  d93:"同じトークンは選択できません",
  d94:"先にリーダーをバインドしてください",
d95:"可燃残量",
  d96:"まず推薦者をバインドしてください",
  d97:"推薦者の招待コードを入力してください",
  d98:"最新のお知らせ",
  d99:"ノート：",
d100:" 2:出金注文は T+1 日に到着する予定です",
d101:"1: 1 回の出金は {min_withdraw} {token_name} から",
d102:"3: 出金ごとに 2 USDT 税がかかります",
d103:"チームアドレス",
d104:"効果的なチーム",
d105:"今日補充してください",
d106:"今日迎えに行く",
d107:"個人的",
d108:"チーム",
d109:"オンにするとキャスト条件が満たされ、自動的にキャストに参加します",
d110:"充電後1〜2分以内に到着する予定です",
d111:"送金ごとに {feerate}% の取引税がかかります",
d112:"ご質問がございましたら、質問フィードバックを残してください。",
d113:"フィードバックの質問",
d114:"フィードバックリスト",
d115:"フィードバック内容",
d116:"オンラインカスタマーサービス",
d117:"まだ返事はありません",
d118:"返事：",
d119:"1. フィードバック",
d120:"2.写真を追加（最大3枚）",
d121:"送信",
d122:"画像はpngまたはjpg形式でアップロードしてください",
d123:"アップロード中です。お待​​ちください...",
d124:"ファイルサイズは10Mを超えることはできません",
d125:"ユーザー",
d126:"CIRCLES  AirDrop マイニングプール",
d127:"発行総額",
d128:"電流出力",
d129:"エキス",
d130:"私の残高",
d131:"チェック",
d132:"シェアする人数",
d133:"働く鉱夫",
d134:"時間",
d135:"点",
d136:"2番",
d137:"採掘",
d138:"マイニングを開始する",
d139:"友達を招待",
d140:"友達がマイニングを始める",
d141:"シェア収入を得る",
d142:"第一レベルの共有",
d143:"住所",
d144:"州",
d145:"もういや",
d146:"二次共有",
d147:"ジェネシス・マイニング",
d148:"入力",
d149:"CIRCLES は、暗号化されたソーシャル プライバシー パブリック チェーンの構築に取り組んでいます。2025 年に開始される予定です。パブリック チェーン トークンの総数は 20 億です。各ユーザーは 1T のコンピューティング パワーを受け取ることができますが、合計が 2025 年になるまで徐々に減少していきます。 80% は無料で採掘されます。",
d150:"総資産評価額",
d151:"9・18パス",
d152: "9・18トークンは、ウォータースワップが協力した初の歴史をテーマにしたコミュニティトークンです。誰もが保有し、決して忘れないを目標に、歴史を忘れず、希薄化を防ぎます。9・18トークンからスタート！今なら無料で手に入れましょう!",
d153:"採掘ルール",
d154: "各ユーザーは 1T の計算能力を得ることができます。各 T の計算能力は、最初の 24 時間で 30 枚のコインを生成します。計算能力は、1,000 万コインが生成されるごとに 20% 減少します。",
d155: "直接招待の場合は 50%、間接招待の場合は 25% のコンピューティング パワー ボーナス インセンティブが与えられます。",
d156: "エアドロップを受信",
d157: "引き出しはまだ開かれていません",
d158: "マーケットリーダーをバインドすると、9.18 918 トークンのエアドロップを受け取ることができます。",
d159: "共有に参加すると、エアドロップを共有して受信するユーザー 10 人ごとに、最大 918 まで、別のエアドロップ報酬を獲得する資格が得られます!",
d160:"私のエアドロップ",
d161:"包括的なエアドロップ",
d162:"私の共有",
d163: "出金はまだ開始されていません",
d164:"現在のレベル",
d165:"収益を表示",
d166:"一般ユーザー",
d167:"VIP会員",
d168:"アップグレード",
d169:"招待コードをコピー",
d170:"住所",
d171:"ステータス",
d172:"生態学",
d173:"カードを保持",
d174: "エコロジー建設に参加して、寛大な報酬を受け取りましょう",
d175:"本日回収可能です",
d176:"C2C取引市場",
d177:"現在のレベル",
d178:"受信",
d179:"入る",
d180:"招待コードがありません",
d181:"マイマーケット",
d182:"購入できません",
d183:"あなたのリーダーはメンバーではありません",
d184: "チェーンのバランスが不十分です",
d185: "プラットフォームのバランスが不十分です",
d186: "署名に失敗しました",
d187:"転送に失敗しました",
d188:"リニューアル",
d189:"招待コード",
d190:"支払う必要があります",
d191:"ウォレット残高",
d192:"バランス",
d193:"プラットフォームバランス",
d194: "支払い中...お待ちください",
d195:"支払いが成功しました",
d196:"支払いを確認",
d197:"閉じる",
d198:"支払いに失敗しました",
d199:"トランザクション",
d200:"コインを獲得",
d201:"エアドロップ",
d202:"コミュニティ",
d203:"エコロジカルセンター",
d204:'ネットワークの選択',
d205:"個",
d206:"受け取った",
d207:"慣れない"
  };
  