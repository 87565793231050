<template>
    <div class="kuangchi">
        <van-popup v-model:show="contentshow" round  >
            <div class="contentshow">
                 <div class="contentshow_title">
                    <span >{{ $t('lang.d153') }}</span>
                    <div @click="contentshow =false">
                        <img src="@/assets/imgsnowball/x_icon.png" style="width: 14px; height: 14px;" alt="">
                    </div>
                  
                 </div>
                 <div class="contentshow_box">
                         <span style="margin-bottom: 43px;">{{ $t('lang.d158') }}</span>
                         <span >{{ $t('lang.d159') }}</span>
                 </div>
            </div>
        </van-popup>
        <div class="line1">
            <div class="l1_title">
                <span>918 Token</span>
                <span>“</span>
                <img src="@/assets/imgsnowball/webhao_icon.png" style="width: 44px; height: 44px;" alt="" @click="contentshow =true">
                <span>”</span>
            </div>
            <!-- <div class="l1_lin">
                <div style="color: #9999; font-size: 12px;">
                    <span>{{ $t('lang.d127') }}</span>
                    <span>{{ $t('lang.d128') }}</span>
                </div>
                <div style="color: var( --text-color); font-size: 20px;">
                    <span>{{ sdata.publish_amount }}</span>
                    <span>{{ sdata.production}} </span>
                </div>
            </div> -->
            <div class="l2_lin">
                <div class="l_box">
                    <img src="@/assets/imgsnowball/k_qianbao.png" class="img" alt="">
                    <span class="button" style="color: #14F6FE;" @click="tiqu">{{ $t('lang.d129') }}</span>
                    <div class="l_main">
                      <span style="color: rgba(84, 84, 84, 1); font-style: 14px;">
                        {{ $t('lang.d160') }}
                      </span>
                      <span style="color: rgba(69, 131, 255, 1); font-size: 20px;">
                     {{ summaryInfo.usable }}
                      </span>
                    </div>
                </div>
                <div class="l_box">
                    <img src="@/assets/imgsnowball/k_gon.png" class="img" alt="">
                    <span class="button" style="color: var( --text-color);" @click="tofenxiang">{{ $t('lang.d131') }}</span>
                    <div class="l_main">
                        <span style="color: rgba(84, 84, 84, 1); font-style: 14px;">
                            {{ $t('lang.d132') }}
                      </span>
                      <span style="color: rgba(69, 131, 255, 1); font-size: 20px;">
                    {{ summaryInfo.dr_num }}
                      </span>
                      </div>
                </div>
            </div>
        </div>
        <div class="line2">
            <img src="@/assets/imgsnowball/konto918_img.png" alt="" style="width: 265px; height: 341px;">
            <!-- <div class="l2_title">{{ $t('lang.d133') }}</div>
            <div class="pos">
                <img src="@/assets/imgsnowball/wwq.png" alt="" style="width: 100%;" class="l2_img1" v-if="staus ==0">
                <img src="@/assets/imgsnowball/wwq.gif" alt="" style="width: 100%;" class="l2_img1" v-else>
       
            </div>
            <div class="timer">
                <div class="t_con">
                    <div>
                        <span class="bum">{{ tim }}</span>
                        <span class="tex">{{ $t('lang.d134') }}</span>
                    </div>
                    <div>
                        <span class="bum">{{ min }}</span>
                        <span class="tex">{{ $t('lang.d135') }}</span>
                    </div>
                    <div>
                        <span class="bum">{{ miao }}</span>
                        <span class="tex">{{ $t('lang.d136') }}</span>
                    </div>
                </div>
            </div> -->
            <!-- <div class="qidon x" v-if="staus ==1">
                {{ $t('lang.d137') }}
            </div> -->
         <div class="qidon" @click="qidonwafun" v-if="summaryInfo.get_amount>0">
            {{ $t('lang.d156') }}  {{ summaryInfo.get_amount }} {{ $t('lang.d205') }}
         </div>
         <div class="qidon " style="background: #919191; opacity: 0.2;" v-else>
            {{ $t('lang.d156') }}  {{ summaryInfo.get_amount }}  {{ $t('lang.d205') }}
         </div>
        </div>
    </div>
</template>

<script>
import Web3 from 'web3'
import { mapState } from 'vuex';
import { kuangchi } from '@/api/index'
export default {
    name: '',
    components: {

    },
    data() {
        return {
            summaryInfo:{},
            contentshow:false
        }
    },
    computed: {
        ...mapState(['account','lang']),
    },
    watch: {
        account() {
           this.init()
        },
        lang() {
            this.init()
          
        },
     
    },
    created() {
          this.init()
    },
    beforeDestroy () {
        window.clearInterval(this.timer)
        this.timer =null
    },
    destroyed () {
        window.clearInterval(this.timer)
        this.timer =null
    },
    methods: {
       async init (){
             this.airdropsummary()
        },
        airdropsummary(){
            kuangchi.airdropsummary(this.account).then(res=>{
             this.summaryInfo = res.data
             console.log('this.summaryInfo',this.summaryInfo);
            })
        },
        tofenxiang(){
            
            this.$router.push({ name: 'fenxiang918' });
        },
        qidonwafun(){
            kuangchi.airdropget(this.account).then(res=>{
                if(res.code == 200 ){
                    this.init()
                }
                this.$toast(res.msg)
            })
        },
        tiqu(){
            this.$toast(this.$t('lang.swap65'))
        },
        
    },
}
</script>

<style lang="less" scoped>
.contentshow{
    width: 335px;
    background: linear-gradient(180deg, rgba(232, 239, 255, 1) 0%,rgba(232, 239, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
    .contentshow_title{
          padding: 20px;
          padding-bottom: 17px;
          border-bottom: 1px solid rgba(242, 242, 242, 1);
          font-size: 16px;
          color: rgba(34, 34, 34, 1);
          font-weight: 700;
letter-spacing: 0px;
line-height: 21px;

display: flex;
align-items: center;
justify-content: space-between;
    }
    .contentshow_box{
        padding: 20px;
        padding-bottom: 40px;
        font-size: 13px;
font-weight: 700;
letter-spacing: 0px;
line-height: 24px;
color: rgba(34, 34, 34, 1);
display: flex;
flex-direction: column;

    }
}
.kuangchi {
    width: 100%;
    background: linear-gradient(180deg, rgba(76, 126, 249, 1) 0%, rgba(150, 208, 255, 1) 100%);

    .line1 {
        width: 100%;
        padding: 20px;

        .l1_title {

            color: var( --text-color);
         width: 100%;
            font-size: 28px;
            font-weight: 700;
            font-family: "HarmonyOSSansSC";
            text-align: center;
            margin-bottom: 4rem !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .l1_lin {
            margin-top: 20px;
            border: 1px solid #56606B;
            height: 95px;
            border-radius: 16px;
            opacity: 1;
            background: #26272b;
            background: linear-gradient(90deg, #26272E 2%, #26272E 100%);
            padding: 20px 15px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                display: flex;
                justify-content: space-between;
            }
        }

        .l2_lin {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 111px;
            margin-top: 45px;

            .l_box {
                width: 48%;
                height: 100%;
                position: relative;
                border-radius: 8px;
                border: 1px solid var( --text-color);
                opacity: 1;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, var( --text-color) 99.31%);


                .img {
                    width: 128.13px;
height: 128.13px;

                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .button{
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    font-size: 14px;
                      font-weight: 500;
                    font-family: "PingFang";
                }
                .l_main{
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  left: 50%;
                  transform: translate(-50%,100%);
                  width: 100%;
                  text-align: center;
                }
            }
        }
    }
    .line2{
       display: flex;
       flex-direction: column;
       align-items: center;
border-radius: 30px 30px 0 0;
opacity: 1;
background: var( --text-color);
  box-sizing: border-box;
  padding: 20px;
 position: relative;
 .pos{
    width: 100%;
    height: 300px;
   
    position: relative;
    .l2_img1{
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
}
.l2_img2{
   position: absolute;
   left: 0px;
   top: 100px;
   width: 300px;
   height: 300px;
}
 }
.l2_title{
 color: rgba(5, 5, 5, 1);
         
            font-size: 20px;
            font-weight: 700;
            font-family: "HarmonyOSSansSC";
            text-align: center;
}
.timer{
    width: 100%;
    display: flex;
    justify-content: center;
    .t_con{
        display: flex;
        width: 37%;
       justify-content: space-between;
       align-items: center;
  div{
    display: flex;
    align-items: center;
  }
       .bum{
        color: #08c5ff;
 font-size: 20px;
 font-weight: 500;
 font-family: "HarmonyOSSansSC";
       }
       .tex{
        color: rgba(176, 176, 176, 1);
 font-size: 12px;
 font-weight: 500;
 font-family: "PingFang";
       }
    }
}
.qidon{
    width: 100%;
    margin-top: 20px;
    height: 60px;
border-radius: 16px;
opacity: 1;
background: rgba(69, 131, 255, 1);
color: var( --text-color);
 font-size: 16px;
 font-weight: 700;
 font-family: "HarmonyOSSansSC";
 text-align: center;
 line-height: 60px;
 margin-bottom: 60px;
}
.y{

}
.x{
   opacity: 0.2;
}
    }
}</style>