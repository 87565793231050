
import tvlAbi from '../config/abi/tvl.json'
import Web3 from 'web3'
//获取个人信息
function userInfo(myaddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(tvlAbi, '0xFFf8C5909d408Dc8a60aFF080a76BFffD989f435');
        crossingOkContractInstance.methods.userInfo('0', myaddress).call().then(res => {
            resolve(res)
        })
    })
}

export default {
    userInfo
}

