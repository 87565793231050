<template>
  <div class="business">
    <div class="contents">
      <div class="headline">
        <span>{{ $t('lang.swap249') }}</span>
      </div>
      <div class="title">
        <span>{{ $t('lang.swap250') }} </span>
        <span> {{ $t('lang.swap243') }}</span>
      </div>
      <div class="quantity ">
        <span>135</span>
        <span>2</span>
      </div>
      <div class="line"></div>

      <div class="title">
        <span>{{ $t('lang.swap251') }}</span>
        <span>{{ $t('lang.swap243') }}</span>
      </div>
      <div class="quantity">
        <span>135</span>
        <span>2</span>
      </div>
      <div class="line"></div>

      <div class="title">
        <span>{{ $t('lang.swap248') }}</span>
        <span> {{ $t('lang.swap243') }}</span>
      </div>
      <div class="quantity">
        <span>135</span>
        <span>2</span>
      </div>
      <div class="line"></div>
      <div>
        <!-- <van-button type="primary"
                    class="receive">{{ $t('lang.swap66') }}</van-button> -->
        <van-button type="primary"
                    class="prohibit"
                    disabled>{{ $t('lang.swap66') }}</van-button>
      </div>
    </div>
    <div class="hash">
      <div>
        <span>{{ $t('lang.swap252') }}</span>
      </div>
      <div class="field">
        <van-field v-model="hashField"
                   class="hash_field"
                   :placeholder="$t('lang.swap254')">
          <template #button>
            <span class="verification">{{ $t('lang.swap253') }}</span>
          </template>
        </van-field>
      </div>
      <div>
        <span>{{ $t('lang.swap255') }}</span>
      </div>
      <div class="field">
        <van-field v-model="addressField"
                   class="hash_field"
                   placeholder="">
        </van-field>
      </div>
      <div>
        <span>{{ $t('lang.swap256') }}</span>
      </div>
      <div class="field">
        <van-field v-model="acquireField"
                   class="hash_field"
                   placeholder="">
        </van-field>
      </div>
      <div class="point">
        <span>{{ $t('lang.swap257') }}</span>
      </div>
      <div>
        <!-- <van-button type="primary"
                    class="receive">{{ $t('lang.swap90') }}</van-button> -->
        <van-button type="primary"
                    class="prohibit"
                    disabled>{{ $t('lang.swap90') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { market } from '@/api/index';
export default {
  data () {
    return {
      hashField: '',//交易哈希
      addressField: '',//交易地址
      acquireField: '',//获得
    };
  },
  methods: {
    init () {
      if (this.account) {

      }
    },

  },
  created () {
    this.init();
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.business {
  padding: 40px 16px 20px;
  // padding-top: 40px;
}
.contents {
  font-size: 14px;

  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  border-radius: 6px;
  padding: 16px 16px 30px;
  background: var( --text-color);
  // margin-top: 40px;
  .headline {
    color: #222222;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #222222;
    padding-top: 14px;
  }
  .quantity {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 14px;
  }
  .line {
    border: 1px solid #f5f5f5;
  }
}
.receive {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px !important;
  margin-top: 40px;
}
.prohibit {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #9b9898 !important;
  background: #d8d8d8;
  border: 1px solid #d8d8d8;
  border-radius: 6px !important;
  margin-top: 40px;
}
.hash {
  color: #666666;
  font-size: 16px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  border-radius: 6px;
  background: var( --text-color);
  padding: 16px 16px 30px;
  margin-top: 20px;
  .field {
    margin: 10px 0 20px !important;
    .hash_field {
      border: 2px solid #f5f5f5;
      border-radius: 6px;
      padding: 8px 10px !important;
    }
  }
  .verification {
    color: #4978ff;
    border-left: 2px solid #f5f5f5;
    padding: 0 10px;
  }
  .point {
    font-size: 14px;
    color: #aeaeae;
  }
}
</style>