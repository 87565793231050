<template>
  <div class="engbill">
    <div class="recruit_title">
      <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>
    <div class="title">
      {{ $t('lang.s130') }}
    </div>
    <div class="receivebox" v-loading="loading">
      <img src="@/assets/imgsnowball/receivebox1.png" alt="">
      <div class="num">
        <div class="num_title">{{ $t('lang.s131') }}</div>
        <div class="num_num" style="opacity: 0.6;">{{ walletinfo.static_claim }}</div>
      </div>
      <div class="num">
        <div class="num_title">{{ $t('lang.s132') }}</div>
        <div class="num_num">{{ walletinfo.static_reward }}</div>
      </div>
      <div class="button" @click="rerveFun(0)">
        {{ $t('lang.s133') }}
      </div>
    </div>
    <div class="title">
      {{ $t('lang.s134') }}
    </div>
    <div class="receivebox" v-loading="loading">
      <img src="@/assets/imgsnowball/receivebox2.png" alt="">
      <div class="num">
        <div class="num_title">{{ $t('lang.s131') }}</div>
        <div class="num_num" style="opacity: 0.6;">{{ walletinfo.share_claim }}</div>
      </div>
      <div class="num">
        <div class="num_title">{{ $t('lang.s132') }}</div>
        <div class="num_num">{{ walletinfo.share_reward }}</div>
      </div>
      <div class="button" @click="rerveFun(1)">
        {{ $t('lang.s133') }}
      </div>
    </div>
    <div class="title">
      {{ $t('lang.s135') }}
    </div>
    <div class="receivebox" style="background: rgba(27, 31, 46, 0.9);" v-loading="loading">
      <img src="@/assets/imgsnowball/receivebox3.png" style="width: 76px;height: 65px;" alt="">
      <div class="num">
        <div class="num_title">{{ $t('lang.s131') }}</div>
        <div class="num_num" style="opacity: 0.6;">{{ walletinfo.rank_claim }}</div>
      </div>
      <div class="num">
        <div class="num_title">{{ $t('lang.s132') }}</div>
        <div class="num_num">{{ walletinfo.rank_reward }}</div>
      </div>
      <div class="button" @click="rerveFun(2)">
        {{ $t('lang.s133') }}
      </div>
    </div>
    <div class="functional_region" id="myElementId">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
          :class="index == regionIndex ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="li first">
        <div>{{ $t('lang.s136') }}</div>
        <div>{{ $t('lang.s137') }}</div>
        <div>{{ $t('lang.s138') }}</div>
      </div>
      <van-list v-model="recordlist.loading" :finished="recordlist.finished" :finished-text="`${$t('lang.d145')}`"
      @load="onLoadrecord">
      <div class="li" v-for="(item, index) in recordlist.list" :key="index">
        <div style="color: rgba(153, 153, 153, 1);">{{ item.created_at }}</div>
        <div style="color: rgba(56, 248, 252, 1);">+{{ item.usable_change }}</div>
        <div style="color: rgba(153, 153, 153, 1);">{{ item.token }}</div>
      </div>
      </van-list>
    
    </div>
  </div>
</template>

<script>
import { nft } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data() {
    return {
      functionOption: [{ name: 'lang.s139' }, { name: 'lang.s140' }, { name: 'lang.s141' }],
      regionIndex: '0',
      walletinfo: {},
      loading:true,
      recordlist: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          pageSize: 10,
          type: '0'
        }

      }
    }
  },
  components: {

  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {

      this.init()
    },
    lang() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    switchovers(index) {
      this.regionIndex = index;
  
      this.recordlist ={
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          pageSize: 10,
          type: '0'
        }

      }
      this.recordlist.info.type = String(index);
       this.nftwalletrecord()

    },
    toprouter() {
      this.$router.go(-1);

    },
    init() {
      
      this.loading = true
      this.regionIndex = '0'
      this.recordlist ={
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          pageSize: 10,
          type: '0'
        }

      }
      this.nftwalletinfo()
      this.nftwalletrecord()
    },
    onLoadrecord(){
         this.nftwalletrecord()
    },
    async rerveFun(index) {
      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('NFT Claim'), this.account);
      } catch (error) {
        return;
      }


      let data = {
        type:index,
        signature: sign
      }
      nft.nftholdclaim(data, this.account).then(res => {
                if(res.code == 200){
                    this.init()
                }
                this.$toast(res.msg)
      })
    },
    nftwalletrecord() {

      nft.nftwalletrecord(this.recordlist.info, this.account).then(res => {
        if (res.data.list != null || res.data.list.length > 0) {
          if (this.recordlist.info.page == 1) {
            this.recordlist.list = res.data.list
          } else {
            this.recordlist.list = this.recordlist.list.concat(res.data.list)
          }
          if (res.data.list.length < this.recordlist.info.pageSize) {
            this.recordlist.loading = true
            this.recordlist.finished = true
          } else {
            this.recordlist.info.page++
          }

        }

      })
    },
    nftwalletinfo() {
      nft.nftwalletinfo(this.account).then(res => {
        if (res.code == 200) {
          this.walletinfo = res.data
          this.loading = false
        }
      })
    },

  },
}

</script>
<style scoped lang='less'>
.functional_region {

  .region {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 80%;

    .special {
      font-size: 18px;
      color: #fff;
    }
  }
}

.engbill {
  padding: 20px;
  padding-top: 52px;
  padding-bottom: 44px;

}

.receivebox {
  margin-bottom: 28px;
  flex-wrap: wrap;
  padding: 20px 15px 20px 8px;
  width: 100%;
  height: 95px;
  opacity: 1;
  border-radius: 8px;
  background: linear-gradient(90deg, rgba(62, 75, 120, 1) 0%, rgba(27, 31, 46, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 56px;
    height: 56px;
  }

  .button {
    align-self: end;
    padding: 2px 12px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(102, 61, 184, 1);
  }

  .num {
    .num_title {
      font-size: 12px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6);
    }

    .num_num {
      font-size: 18px;
      font-weight: 500;
      color: rgba(56, 248, 252, 1);
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 12px;
}

.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin-top: 29px;
  padding: 15px 20px;
  width: 100%;
  border-radius: 16px;
  background: rgba(27, 31, 46, 1);

  border: 1px solid rgba(36, 40, 52, 1);

  .first {
    color: rgba(153, 153, 153, 1);
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .li {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 700;

    &>div {
      width: calc(100% / 3);
      text-align: center;
    }

    &>div:first-child {
      text-align: left;
    }

    &>div:last-child {
      text-align: right;
    }
  }
}
</style>