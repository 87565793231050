// import addresses from '@/config/constants/contracts.js';
const addresses = {
  jackRouter: '0x22A7813978f5589c6fBa94983Be7169Eb796B3F2',
  jackFactory: '0x5CD2a6e2531b14FFDc005C642D2ACc2159131B27',

  jackRouterV2: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  jackFactoryV2: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
};

export const getAddress = address => {
  return address;
};

export const getJackFactoryAddress = () => {
  return getAddress(addresses.jackFactory);
};

export const getJackRouterAddress = () => {
  return getAddress(addresses.jackRouter);
};

export const getJackFactoryAddressV2 = () => {
  return getAddress(addresses.jackFactoryV2);
};

export const getJackRouterAddressV2 = () => {
  return getAddress(addresses.jackRouterV2);
};

export const getWethAddress = () => {
  return getAddress(addresses.weth);
};
