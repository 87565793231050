import request from '../utils/request';
export default {
    //启动挖矿
    postminerstart(data,address,language){
        return request.request({
            url: '/miner/start',
            method: "post",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
     //挖矿信息
     getminerinfo(address,language){
        return request.request({
            url: '/miner/info',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
          
        })
    },
      //汇总信息
      getminersummary(address,language){
        return request.request({
            url: '/miner/summary',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
          
        })
    },
      //账单类型
      getminerwalletbehaviors(address,language){
        return request.request({
            url: '/miner/wallet/behaviors',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
          
        })
    },
     //账单列表
     getminerwalletrecordlist(data,address,language){
        return request.request({
            url: '/miner/wallet/record-list',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
          params:data
        })
    },
      //团队一级二级列表
      getminerwalletrecordlist(data,address,language){
        return request.request({
            url: '/miner/team-list',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
          params:data
        })
    },
    //空投直推列表
    airdropchildrenlist(data,address,language){
        return request.request({
            url: '/airdrop/children-list',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
          params:data
        })
    },
    //空投汇总信息
    airdropsummary(address,language){
        return request.request({
            url: '/airdrop/summary',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
     //空投领取
     airdropget(address,language){
        return request.request({
            url: '/airdrop/get',
            method: "post",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            
        })
    },
}