<template>
  <div class="visual_chart">
    <div class="head">
      <div>
        <img class="currency"
             src="@/assets/img/BNB.png"
             alt="">
        <img class="currency"
             src="@/assets/img/usdt-new.png"
             alt="">
        <span>BNB/USDT</span>
      </div>
      <div>
        <img class="share"
             src="@/assets/img/cancel.png"
             @click="close">
      </div>
    </div>
    <div class="detailed">
      <div class="detailed_left">
        <div>
          <span class="number">61.18</span>
          <span>BNB/USDT</span>
          <span class="special">
            -2.358 (-3.71%)</span>
        </div>
        <div class="time">
          <span>{{dateFormat(new Date(),'yyyy-MM-dd-hh-mm')}}</span>
        </div>
      </div>
      <div class="region">
        <div v-for="(item,index) of functionOption"
             :key="index">
          <span :class="index ==regionIndex?'special':''"
                @click="switchovers(index)">{{$t(item.name)}}</span>
        </div>
      </div>
    </div>
    <!-- 图表 -->
    <div class="data_graph">

    </div>
  </div>
</template>

<script>
import { dateFormat } from "../../utils/format";
export default {
  data () {
    return {
      // 功能列表
      functionOption: [{ name: '24小时' }, { name: '1周' }, { name: '1个月' }, { name: '1年' }],
      regionIndex: '0', //当前的时间
    }
  },
  methods: {
    switchovers (index) {
      this.regionIndex = index
    },
    close () {
      this.$emit('VisualChartClose')
    },
    dateFormat
  }
}
</script>

<style lang="less" scoped>
.visual_chart {
  background: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid rgb(231, 227, 235) !important;
  padding: 24px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
    }
    .basics {
      background: #3399ff;
      padding: 4px 8px;
      border-radius: 6px;
    }
    .currency {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 6px;
    }
    .share {
      cursor: pointer;
      width: 20px;
    }
  }
  .detailed {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    .detailed_left {
      font-size: 14px;
      color: rgb(122, 110, 170);
      font-weight: bold;
      margin: 10px 0;
      .number {
        font-size: 26px;
        font-weight: bold;
        color: #000;
      }
      .special {
        // font-size: 10px;
        color: #3399ff;
      }
      .time {
        color: #666666;
        font-size: 10px;
      }
    }
    .region {
      display: flex;
      justify-content: space-between;
      max-width: 350px;

      line-height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #000;
      font-weight: 600;
      border: 1px solid #3399ff;
      border-radius: 34px;
      margin: 6px 0;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 192rpx;
        padding: 6px 20px;
        cursor: pointer;
      }
      .special {
        background: #3399ff;
        color: #000;
        box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 16px;
      }
    }
  }
  .data_graph {
    height: 200px;
  }
}
</style>