<template>
  <div class="swapDetail">
    <div class="van-dialog__header">
      <div class="text">{{ $t("lang.swap114") }}</div>
      <div class="cancel"
           @click="handleClose"></div>
    </div>
    <div class="btContent"
         v-if="addliquidityDetail == 0">
      <div class="tokenInfo">
        
        <div class="leftItem">
          <div class="input">{{(swapDetail.lpBalance).toFixed(6)}}</div>
          <img class="tokenImg"
               :src="fromCur.logoURI"
               alt />
          <img class="tokenImg"
               :src="toCur.logoURI"
               alt />
        </div>
        <div class="symbol">
          <span>{{ fromCur.symbol }} / {{ toCur.symbol }} Pool Tokens</span>
        </div>
      </div>
      <div class="tip">
        {{ $t("lang.swap28", { inputOutMin: swapDetail.inputOutMin,symbol: toCur.symbol,})}}
      </div>
      <div class="wrap">
        <div class="wrapItem">
          <div>
            <span>{{ $t("lang.swap117") }} {{ fromCur.symbol }}</span>
          </div>
          <div class="opppsite">
            <img :src="fromCur.logoURI"
                 class="image" />
            <span>{{ (swapDetail.fromInput * 1).toFixed(6) }}</span>
          </div>
        </div>
        <div class="wrapItem">
          <div>{{ $t("lang.swap117") }} {{ toCur.symbol }}</div>
          <div class="opppsite">
            <img :src="toCur.logoURI"
                 class="image" />
            <span>{{ (swapDetail.toInput * 1).toFixed(6) }}</span>
          </div>
        </div>
        <div class="wrapItem">
          <div>{{ $t("lang.swap119") }}</div>
          <div class="priceItem">
            <div>
              1{{ fromCur.symbol }} =
              {{ ((swapDetail.toInput / swapDetail.fromInput)).toFixed(6) }}
              {{ toCur.symbol }}
            </div>
            <div>
              1{{ toCur.symbol }} =
              {{ ((swapDetail.fromInput / swapDetail.toInput)).toFixed(6) }}
              {{ fromCur.symbol }}
            </div>
          </div>
        </div>
        <div class="wrapItem">
          <div>{{ $t("lang.swap96") }}:</div>
          <!-- <div class="special">
            {{ percentage }}
          </div>-->
          <div class="special"
               v-if="this.percentage < 0.01">{{ this.percentageMin }}</div>
          <div class="special"
               v-else-if="this.percentage >= 100">{{ this.percentageMax }}</div>
          <div class="special"
               v-else>{{ this.percentage }}%</div>
        </div>
      </div>
      <van-button type="primary"
                  class="confirmBtn"
                  @click="handleConfirm">
        <span>{{ $t("lang.swap118") }}</span>
      </van-button>
    </div>
    <div class="btContent"
         v-else-if="addliquidityDetail == 1">
      <div class="tokenInfo">
        <div class="leftItem special">
          <div class="input">{{ removeDataCopy.fromRecent }}</div>
          <div>
            <!-- <img class="tokenImg" :src="fromCur.logoURI" alt="" /> -->
            <span>{{ removeDataCopy.fromSymbol }}</span>
          </div>
        </div>
        <div>
          <span>+</span>
        </div>
        <div class="leftItem special">
          <div class="input">{{ removeDataCopy.toRecent }}</div>
          <div>
            <!-- <img class="tokenImg" :src="toCur.logoURI" alt="" /> -->
            <span>{{ removeDataCopy.toSymbol }}</span>
          </div>
        </div>
      </div>

      <div class="tip">
        <span>{{ $t("lang.swap115",{slippage:slippage}) }}</span>
      </div>

      <div class="wrap">
        <div class="wrapItem">
          <div>
            <span>
              {{ $t("lang.swap116") }} {{ removeDataCopy.fromSymbol }}/{{
              removeDataCopy.toSymbol
              }}
            </span>
          </div>
          <div class="opppsite">
            <span>{{ removeDataCopy.lpRecent}}</span>
          </div>
        </div>
        <div class="wrapItem">
          <div>{{ $t("lang.swap7") }}</div>
          <div class="priceItem">
            <div>
              1{{ removeDataCopy.fromSymbol }} =
              {{
              (removeDataCopy.toBalance / removeDataCopy.fromBalance)
              }}
              {{ removeDataCopy.toSymbol }}
            </div>
            <div>
              1{{ removeDataCopy.toSymbol }} =
              {{
              (removeDataCopy.fromBalance / removeDataCopy.toBalance)
              }}
              {{ removeDataCopy.fromSymbol }}
            </div>
          </div>
        </div>
      </div>
      <van-button type="primary"
                  class="confirmBtn"
                  @click="handleConfirm">
        <span>{{ $t("lang.swap90") }}</span>
      </van-button>
    </div>
  </div>
</template>

<script>
import { significantDigits } from '@/utils/format.js';
import { mapState } from 'vuex';
import { homedata } from '../../api';
export default {
  props: ['fromCur', 'toCur', 'percentage', 'swapDetail', 'removeDataCopy', 'addliquidityDetail'],
  data () {
    return {
      priceDirection: true,
      opppsitePrice: '',
      percentageMin: '<0.01%',
      percentageMax: '>100%',
      imgurl:''
    };
  },
  created () {
    homedata.gettokenimgurl().then(res =>{
      this.imgurl =res.data.image_domain

    })
  },
  methods: {
    handleConfirm () {
      this.$emit('listenConfirm');
    },
    handleClose () {
      this.$emit('listenClose');
    },
    significantDigits,
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath']),
  },
  mounted () { },
};
</script>

<style lang="less" scoped>
.swapDetail {
  background: var( --text-color);

  // width: 350px;
  .van-dialog__header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin: 0 20px;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid #282f3a;
    color: #000;

    .cancel {
      width: 14px;
      height: 14px;
      background-image: url('~@/assets/img/cancel.png');
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .btContent {
    padding: 0 16px;
  }
  .down {
    height: 20px;
    width: 20px;
    margin-left: 4px;
    transform: rotate(90deg);
  }
  .tokenInfo {
    padding: 12px 0;
    .leftItem {
      display: flex;
      align-items: center;
      .input {
        font-size: 30px;
        font-weight: bold;
        color: #000;
      }
      .tokenImg {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        margin-left: 6px;
      }
    }
    .special {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;
        font-size: 20px !important;
      }
    }
    .symbol {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      margin-top: 4px;
    }
  }
  .tip {
    font-weight: 500;
    line-height: 20px;
    font-size: 12px;
    // font-family: 创艺简黑体-Regular, 创艺简黑体;
    color: #000;
    margin-top: 10px;
  }
  .wrap {
    padding: 4px 0;
    font-size: 14px;
    margin-top: 20px;
    .wrapItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #000;
      padding: 4px 0;
      .priceItem {
        text-align: right;
        color: #000;
        font-weight: bold;
      }
      .opppsite {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #000;
        .image {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }

      .special_color {
        color: #3399ff;
      }
      .special {
        text-align: right;
        color: #000;
        font-weight: bold;
      }
    }
  }
  .confirmBtn {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    border-radius: 16px;
    background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
    border: 0;
    padding: 30px 0;
    margin: 22px 0;
  }
}
</style>
