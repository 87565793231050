<template>
  <div class="position">
    <div class="lianchuang">
      <div>
        <img src="@/assets/img/vgt_bg.png"
             class="lianchuang_img" />
      </div>
      <div class="content">
        <div class="genesis">
          <span>GENEIS</span>
        </div>
        <div class="information">
          <!-- <div class="li">
            <span>{{ $t('lang.swap552') }}</span>
          </div> -->
          <!-- <div class="li">
            <div>
              <span>{{$t('lang.swap260')}}</span>
              <span>{{$t('lang.swap243')}}</span>
            </div>
            <div>
              <span>132</span>
              <span>132</span>
            </div>
          </div> -->
          <div class="li">
            <div>
              <span>{{ $t('lang.swap261') }}</span>
              <span>{{ $t('lang.swap243') }}</span>
            </div>
            <div>
              <span>{{ reward }}</span>
              <span>{{ addition }}</span>
            </div>
          </div>
        </div>
        <div>
          <van-button v-if="reward != 0"
                      type="primary"
                      class="receive"
                      @click="receiveClick">{{ $t('lang.swap66') }}</van-button>
          <van-button v-else
                      type="primary"
                      class="prohibit"
                      disabled>{{ $t('lang.swap66') }}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { coin, market } from '@/api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      reward: 0,
      addition: 0,
      token_id: undefined,
    };
  },
  created () {
    this.init();
    this.getReward();
  },
  methods: {
    init () {
      if (this.account) {
        this.getReward();
      }
    },
    getReward () {
      coin.rewardVgtInfo(this.account).then(res => {
        if (res.code == 200) {
          this.reward = res.data.reward;
          this.addition = res.data.addition;
          this.token_id = res.data.token_id;
        }
      });
    },
    receiveClick () {
      if (this.account == '') return;
      if (this.useReward * 1 == 0) {
        this.$toast(this.$t('lang.swap218'));
        return;
      }
      let data = {
        coin_id: this.token_id,
      };
      market.marketRewardOut(data, this.account, this.language).then(res => {
        if (res.code == 200) {
          this.$toast(this.$t('lang.swap67'));
        } else {
          this.$toast(res.msg);
        }
        this.init();
        // console.log(res, '权益池取出');
      });
    },
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath']),
  },
};
</script>

<style lang="less" scoped>
.position {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 94%;
  left: 0;
  top: 50px;
}
.lianchuang {
  position: relative;
  height: 100%;
  background: url('~@/assets/img/lianchuang_bg.png') no-repeat;
  background-size: 100% 100%;
  padding: 240px 20px 60px;
  .lianchuang_img {
    position: absolute;
    left: 0;
    top: -6%;
    width: 100%;
  }
  .price {
    display: flex;
    justify-content: center;
    color: #ffa826;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .content {
    background: #fdfdfd;
    border: 1px solid #e0dde2;
    border-radius: 20px;
    padding: 20px 20px 30px;
    .genesis {
      display: flex;
      justify-content: center;
      color: #222222;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .information {
      border: 1px solid #f5f5f5;
      color: #000;
      // font-weight: bold;
      border-radius: 10px;
      padding: 1px 10px 20px;
      .li {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        font-size: 12px;
        margin-top: 20px;
        div {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          color: #000;
          margin-top: 6px;
        }
      }
    }
    .receive {
      width: 100%;
      font-size: 16px;
      font-weight: normal;
      border-radius: 6px !important;
      margin-top: 40px;
    }
    .prohibit {
      width: 100%;
      font-size: 16px;
      font-weight: normal;
      color: #9b9898 !important;
      background: #d8d8d8;
      border: 1px solid #d8d8d8;
      border-radius: 6px !important;
      margin-top: 40px;
    }
  }
}
</style>