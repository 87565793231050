<template>
  <div class="">
    邀请
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  methods: {

  },
}
</script>

<style lang="less" scoped>
</style>