<template>
  <div class="iso">
    <div class="i_main" :style="`background: url(${ColorCheckData=='black'? bglist.image: bglist.image1}) no-repeat;`">
      <div class="m_title">{{$t('lang.h47')}}</div>
      <div class="m_text">{{$t('lang.h48')}}</div>
      <div class="buttons">
        <div class="b_b1">LP池</div>
        <div class="b_b1">单币池</div>
      </div>
    </div>
    <div class="i_sosuo">
      <div class="s_line1">
        <div class="region">
          <div v-for="( item, index) of switchlist" :key="index" @click="shiswitch(index)"
            :class="index == regionIndex ? 'special' : ''">
            <span>{{ $t(item) }}</span>
          </div>
        </div>
        <div class="l_zhi">
          <van-switch v-model="checked" size="18px" active-color="rgba(182, 133, 255, 1)" inactive-color="#fff" />
          <span>{{ $t('lang.swap360') }}</span>
        </div>

      </div>
      <div class="i_pai">
        <div class="p_li">
          <span>{{ $t('lang.swap361') }}</span>
          <div class="menu">
            {{ $t('lang.swap363') }}
            <van-icon name="play" class="m_icon" @click="remeshowfun" />
            <div class="over" v-show="remenshow"></div>
          </div>
        </div>
        <div class="p_li">
          <span>{{ $t('lang.swap362') }}</span>
          <van-field type="number" v-model="toInput" input-align="left" 
            :placeholder="`${$t('lang.swap364')}`" class="van-field" />
        </div>
      </div>
    </div>
    <div class="i_lon">
      <!-- <div class="meiyou">
        <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData == 'black'">
                        <img src="@/assets/img/zanwu1.png" alt="" v-else>
            <span>{{ $t('lang.swap365') }}</span>
        </div> -->
      <template >
        <div class="lon_list" style="margin-bottom: 26px;">
          <div class="lon_title">
            <div class="title_img">
              <img src="@/assets/tokenlogo/RUSD.png" alt="">
              <img src="@/assets/img/logo/USDT.png" alt="" style="margin-left: -10px;">
            </div>
            <span style="color: rgba(255, 255, 255, 1);">RUSD-USDT  </span>
          </div>
          <div class="lon_line" style="margin-bottom: 22px;">
            <div class="line_left">
              <span style="color: rgba(153, 153, 153, 1);">已赚取</span>
              <span style="color: rgba(255, 255, 255, 1);">{{ significantDigits((MiningData.profit * 1).toFixed(6)) }}</span>
            </div>
            <div class="line_right_button"   @click="harvest('0')">领取</div>
          </div>
          <div class="lon_line2" style="margin-bottom: 18px;"   v-if="MiningData.allowanceToRouter">
            <div class="line2_title">质押</div>
            <van-button type="primary"
           
                          class="line2_button"
                          @click="confirmRemovalV2"
                          loading-text="Waiting..."
                          :loading="MiningData.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>

          </div>
          <div class="lon_line" style="margin-bottom: 22px;" v-else>
            <div class="line_left">
              <span style="color: rgba(153, 153, 153, 1);">质押</span>
              <span style="color: rgba(255, 255, 255, 1);">{{ significantDigits((MiningData.myNumber * 1).toFixed(6)) }}</span>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectV2(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectV2(true)" />
            </div>
          </div>
          <div class="lon_line3" >
            <div class="line3_left">累计激励</div>
            <div class="line3_right"><span style="color: rgba(152, 232, 110, 1);">{{ ((MiningData.allReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>  RUSD</div>
          </div>
          <div class="lon_line3">
            <div class="line3_left">今日激励</div>
            <div class="line3_right"><span style="color: rgba(152, 232, 110, 1);">{{ ((MiningData.todayReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>  RUSD</div>
          </div>
        </div>
        <!-- RWA -->
        <div class="lon_list" style="margin-bottom: 26px;">
          <div class="lon_title">
            <div class="title_img">
              <img src="@/assets/tokenlogo/RWA.png" alt="">
            
            </div>
            <span style="color: rgba(255, 255, 255, 1);">RWA  </span>
          </div>
          <div class="lon_line" style="margin-bottom: 22px;">
            <div class="line_left">
              <span style="color: rgba(153, 153, 153, 1);">已赚取</span>
              <span style="color: rgba(255, 255, 255, 1);">{{ significantDigits((RWAData.profit * 1).toFixed(6)) }}</span>
            </div>
            <div class="line_right_button"   @click="RWAHarvest('0')">领取</div>
          </div>
          <div class="lon_line2" style="margin-bottom: 18px;"   v-if="RWAData.allowanceToRouter">
            <div class="line2_title">质押</div>
            <van-button type="primary"
           
                          class="line2_button"
                          @click="approveRWA"
                          loading-text="Waiting..."
                          :loading="RWAData.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>

          </div>
          <div class="lon_line" style="margin-bottom: 22px;" v-else>
            <div class="line_left">
              <span style="color: rgba(153, 153, 153, 1);">质押</span>
              <span style="color: rgba(255, 255, 255, 1);">{{ significantDigits((RWAData.myNumber * 1).toFixed(6)) }}</span>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectRWA(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectRWA(true)" />
            </div>
          </div>
          <div class="lon_line3" >
            <div class="line3_left">累计激励</div>
            <div class="line3_right"><span style="color: rgba(152, 232, 110, 1);">{{ ((RWAData.allReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>  RUSD</div>
          </div>
          <div class="lon_line3">
            <div class="line3_left">今日激励</div>
            <div class="line3_right"><span style="color: rgba(152, 232, 110, 1);">{{ ((RWAData.todayReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>  RUSD</div>
          </div>
        </div>
      </template>

    </div>
    <van-popup v-model="MiningPopupShow"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirm"
                   @getMyAddress="getBalance"
                   :selectShow="selectShow"
                   :max="max"
                   currencyCharacter="RUSD-USDT"></MiningPopup>
    </van-popup>
    <van-popup v-model="MiningPopupShowTwo"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmTwo"
                   @getMyAddress="getBalanceTwo"
                   :selectShow="selectShowTwo"
                   :max="max"
                   currencyCharacter="RWA"></MiningPopup>
    </van-popup>
  </div>
</template>

<script>
import MiningPopup from '@/views/index/MiningPopup.vue';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import { significantDigits, parseAmount, formatAmount } from '@/utils/format.js';
import lp from '../../methods/lp.js';
import RWAlp from '../../methods/RWAlp.js';
export default {
  name: 'iso',
  components: {

  },
  data() {
    return {
      regionIndex: 0,
      toInput:'',
      checked: false,
      remenshow: false,
      bglist:{
        image:require('@/assets/img/earnmoneybg.png'),
        image1:require('@/assets/img/earnmoneybg1.png'),
      },
      switchlist: ["lang.swap358", "lang.swap359"],
      selectShow: false, //true:质押,false:赎回
      selectShowTwo: false, //true:质押,false:赎回
      MiningPopupShow:false,
      MiningPopupShowTwo:false,
      max: 0,
      MiningData:{
          confirmBtnShow: false, //是否授权
          showApprove: false, //是否授权
          allowanceToRouter: false,
          myNumber: 0,
          profit: 0,
          balance: 0, //
          max: 0,
          allReward: 0,
          todayReward: 0,
          userTvl: 0,
          maxTVL: 0,
          OtherTVL: 0,
          power: 0,
          pledge_num: 0,
        },
        RWAData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
    }
  },
  components: {
    MiningPopup
  },
  computed: {
    ...mapState(['account', 'lang','ColorCheckData']),
  },
  created() {
      this.init()
  },
  watch: {
      account(){
        this.init()
      }
  },
  methods: {
    significantDigits,
    shiswitch(index) {
      this.regionIndex = index;
    },
    init(){
      if (this.account) {
      this.handleApproveBtnShow();
      this.getBalanceOf();
      this.getDeposit();
      this.getReward();
      this.getpoolReward();
      // this.getlockData();
    }
    },
    
    getpoolReward () {
      lp.poolRewardPOR().then(res => {
     
        this.MiningData.allReward = res[0];
        this.MiningData.todayReward = res[1];
      });
      RWAlp.poolReward().then(res => {
     
     this.RWAData.allReward = res[0];
     this.RWAData.todayReward = res[1];
   });
    
    },
    async handleApproveBtnShow () {
      if (this.account) {
        const erc20ContractV2 = getErc20Contract('0xC12babE2c0ba548A1B7aE5ecb0ea77f6D30dC71B');
        const toV2 = '0x3ecdc794239297978f1734d7fc2aeccccf90fe9e';
        const allowanceV2 = await erc20ContractV2.methods.allowance(this.account, toV2).call();
        console.log(allowanceV2,'allowanceV2');
        if (allowanceV2 == 0) {
          this.MiningData.allowanceToRouter = true;
        } else {
          this.MiningData.allowanceToRouter = false;
        }
        

        const phoContract = getErc20Contract('0x6b0D6b94a71697AD57Cf6604060f2E6A37B9687c'); //获取Pho币种
        const toMining = '0x3df019b4bf155292c3b25c954454d3551d97a750';
        const appBalance = await phoContract.methods.allowance(this.account, toMining).call();
        if (appBalance == 0) {
          this.RWAData.allowanceToRouter = true;
        } else {
          this.RWAData.allowanceToRouter = false;
        }
      }
    },
    getBalance (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShow = false;
      if (this.selectShow) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap158')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
      
       
          lp.depositPOR(this.account, 0, amount, transactionsDetail)
            .then(res => {
              this.getDeposit();
              this.getBalanceOf();
             
            })
            .catch(e => { });
      

      
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap159')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        
          lp.withdrawPOR(this.account, 0, amount, transactionsDetail)
            .then(res => {
              this.getDeposit();
              this.getBalanceOf();
            })
            .catch(e => { });
       
      }
    },
    getDeposit () {
      lp.userinfoPOR(this.account, '0').then(res => {
        this.MiningData.myNumber = res.amount / Math.pow(10, 18);
      });
      

      RWAlp.userinfo(this.account,'1').then(res => {
        this.RWAData.myNumber = res.amount / Math.pow(10, 18);
      });
    
    },
    getReward () {
      lp.pendingRewardPOR('0', this.account).then(res => {
        this.MiningData.profit = res / Math.pow(10, 18);
      });
      RWAlp.pendingReward('0',this.account).then(res => {
        this.RWAData.profit = res / Math.pow(10, 18);
      });
     
    },
    async getBalanceOf () {
      if (this.account) {
        const erc20ContractV2 = getErc20Contract('0xC12babE2c0ba548A1B7aE5ecb0ea77f6D30dC71B');
        const balanceV2 = await erc20ContractV2.methods.balanceOf(this.account).call();
        this.MiningData.balance = formatAmount(balanceV2, 18);
     

        const erc20ContractPho = getErc20Contract('0x6b0D6b94a71697AD57Cf6604060f2E6A37B9687c');
        const balancePho = await erc20ContractPho.methods.balanceOf(this.account).call();
        this.RWAData.balance = formatAmount(balancePho, 18);
      }
    },
    getBalanceTwo (res) {
      let amount = parseAmount(res, 18);
      console.log(amount,'amount');
      
      this.MiningPopupShowTwo = false;
      if (this.selectShowTwo) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap158')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
      
       
          RWAlp.deposit(this.account, 0, amount, transactionsDetail)
            .then(res => {
              this.getDeposit();
              this.getBalanceOf();
             
            })
            .catch(e => { });
      

      
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap159')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        
          RWAlp.withdraw(this.account, 0, amount, transactionsDetail)
            .then(res => {
              this.getDeposit();
              this.getBalanceOf();
            })
            .catch(e => { });
       
      }
    },
    remeshowfun() {
      this.remenshow = !this.remenshow
    },
    harvest (i) {
      if (this.MiningData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      lp.harvestPOR(this.account, 0, transactionsDetail)
        .then(res => {
          this.getReward();
        })
        .catch(e => { });
    },
    RWAHarvest () {
      if (this.RWAData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap146')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      RWAlp.claimReward(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },
    selectRWA (state) {
      this.selectShowTwo = state;
      if (state) {
        this.max = this.RWAData.balance;
      } else {
        this.max = this.RWAData.myNumber;
      }
      this.MiningPopupfirmTwo();
    },
    selectV2 (state) {
      this.selectShow = state;
      if (state) {
        this.max = this.MiningData.balance;
      } else {
        this.max = this.MiningData.myNumber;
      }
      this.MiningPopupfirm();
    },
    MiningPopupfirm () {
      this.MiningPopupShow = !this.MiningPopupShow;
    },
    MiningPopupfirmTwo () {
      this.MiningPopupShowTwo = !this.MiningPopupShowTwo;
    },
    approveRWA () {
      this.RWAData.showApprove = true;
      const erc20Contract = useErc20Contract('0x6b0D6b94a71697AD57Cf6604060f2E6A37B9687c');
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve('0x3df019b4bf155292c3b25c954454d3551d97a750', amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.RWAData.showApprove = false;
          this.RWAData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.RWAData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    async confirmRemovalV2 () {
      this.MiningData.showApprove = true;
      const erc20Contract = useErc20Contract('0xC12babE2c0ba548A1B7aE5ecb0ea77f6D30dC71B');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0x3ecdc794239297978f1734d7fc2aeccccf90fe9e', amo).estimateGas({from:this.account})
      erc20Contract.methods
        .approve('0x3ecdc794239297978f1734d7fc2aeccccf90fe9e', amo)
        .send({
          from: this.account,
          gas,
          gasPrice:parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.MiningData.showApprove = false;
          this.MiningData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.MiningData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
  },
}
</script>

<style lang="less" scoped>
.iso {
  
  background: var(--bg-color);

}

.i_main {
text-align: center;
  position: relative;
  width: 100%;
  height: 500px;
  // background: url(~@/assets/img/earnmoneybg.png)no-repeat;
  background-size:   contain !important;
  background-position: 0 130px !important;
  // background-size: 130% 243px !important;
  // background-position: -60px 150px !important;
  padding: 20px;

  .m_title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 32.82px;
    color: var( --text-color);
    text-align: center;
    margin-top: 40px;
  }

  .m_text {
    margin-top: 7px;
    text-align: center;
    color: rgba(158,156,163, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: "HarmonyOSSansSC";


  }

  .buttons {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 40px;

    .b_b1 {

      border-radius: 16px;

      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: rgba(0, 0, 0, 1);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 160px;
      height: 48px;
      opacity: 1;
      border-radius: 72px;
      background: rgba(152, 232, 110, 1);
box-shadow: 0px 4px 8px  rgba(255, 255, 255, 0.12);
    }
  }
}

.i_sosuo {
  width: 100%;
  padding: 21px;

  .s_line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .region {
      padding: 3px;
      width: 130px;
      height: 40px;
      border-radius: 19px;
      border: 1px solid rgba(180, 135, 255, 0.71);
      display: flex;
      line-height: 38px;
      font-size: 12px;
      font-weight: 700;
      font-family: "PingFang";
      color: rgba(158,156,163, 1);
      // margin: 20px 0;
      margin-bottom: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        // width: 192rpx;
        padding: 16px 0;
      }

      .special {
        // width: 50%;
        color: #000;
        background: rgba(152, 232, 110, 1);
        // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 34px;
      }
    }

    .l_zhi {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      span {
        opacity: 0.6;
        color: var( --text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
        margin-left: 5px;
      }

      /deep/.van-switch__node {
        background: var( --text-color);
      }
    }

  }

  .i_pai {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p_li {
      height: 100%;
      color: var( --text-color);

      span {
        opacity: 0.6;
        margin-bottom: 20px;

      }
    }

  }

}

.menu {
  position: relative;
  margin-top: 20px;
  width: 137px;
  height: 50px;
  border-radius: 61px;
  opacity: 1;
  background: var( --line-bg-color);
  padding-left: 14px;
  line-height: 50px;
  color: var( --text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: "PingFang";

  .m_icon {
    transform: rotate(90deg);
    color: var( --text-color);
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .over {
    width: 100%;
    height: 100px;
    position: absolute;
    background: var( --text-color);
    bottom: -110px;
    z-index: 9999;
    left: 0;
    border-radius: 20px;
  }
}

.van-field {
  width: 178px;
  height: 50px;
  border-radius: 61px;
  color: #bababaff;
  font-size: 16px;
  font-weight: 700;
  font-family: "PingFang";
  background: var( --line-bg-color);
  margin-top: 20px;
  padding: 14px 14px !important;

  /deep/ .van-field__control {
    color: #000;
  }
}

.i_lon {
  width: 100%;
  min-height: 361px;
  border-radius: 30px 30px 0 0;
  margin-top: 20px;
  background: var( --box-bg-color);
  position: relative;
  padding: 28px 20px;


  .lon_list {
    padding: 19px 0px;
    width: 100%;
     border-bottom: 8px solid rgba(16, 20, 32, 1);
    opacity: 1;
   
    

    .lon_title {
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(0, 0, 0, 1);

      .title_img {
        margin-right: 5px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }
    }

    .lon_line {
      padding: 16px 17px;
      width: 100%;
      height: 86px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(16, 20, 32, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line_left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
      }

      .line_right_button {
        width: 71px;
        height: 32px;
        opacity: 1;
        border-radius: 19px;
        background: rgba(152, 232, 110, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: #000;
      }
    }
    .lon_line2{
      width: 100%;
      height: 109px;
opacity: 1;
border-radius: 16px;
background: rgba(16, 20, 32, 1);
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: 18px;
.line2_title{
  font-size: 18px;
font-weight: 900;
letter-spacing: 0px;
line-height: 18px;
color: rgba(255, 255, 255, 1);
}
.line2_button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 129px;
height: 41px;
opacity: 1;
border-radius: 8px;
background: rgba(152, 232, 110, 1);
font-size: 16px;
font-weight: 700;
letter-spacing: 0px;
line-height: 24px;
color: #000 !important;
border: none;
}
    }
    .lon_line3{
      margin-bottom: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line3_left{
        font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 18px;
color: rgba(153, 153, 153, 1);
      }
      .line3_right{
        font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 18px;
color: rgba(153, 153, 153, 1);
      }
    }
  }

  .meiyou {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 89px;
height: 158px;

      margin-bottom: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(168, 168, 168, 1);
    }
  }
}

.button {
        display: flex;
        align-items: flex-end;
        .confirmBtn {
          height: auto;
          font-size: 16px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          border-radius: 16px;
          padding: 4px 20px;
        }
        .button_img {
          width: 26px;
          height: 26px;
          margin-left: 10px;
        }
      }
</style>