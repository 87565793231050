<template>
  <div class="list">
      <div class="list_li">
        <div class="list_li_title">币种</div>
        <div class="list_li_title">最新价格</div>
        <div class="list_li_title">涨跌幅</div>
      </div>
      <div class="list_li" v-for="(item,index) in quoteList" :key="index">
            <div class="list_li_logo">
                <img :src="item.logo" class="list_li_logo_box" alt="">
                <img src="@/assets/img/rswap/biance.png" class="list_li_logo_abs" alt="">
            </div>
            <div class="list_li_num">${{ item.last }}</div>
            <div class="list_li_numRate">{{item.quote_change}}</div>
      </div>
     
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    quoteList:{
        type:Array,
        default:function () {
      return [];
    }
    }
  },
  components: {

  },
  mounted() {

  },
  methods: {

  },
}

</script>
<style scoped lang='less'>
.list{
    width: 100%;
    padding-top: 16px;
    .list_li{
        margin-bottom: 22px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list_li_title{
            font-size: 12px;
            font-weight: 700;
            color: rgba(161, 161, 161, 1);
        }
        .list_li_num{
            font-size: 14px;
            color: rgba(255, 255, 255, 1);
        }
        .list_li_numRate{
            width: 74px;
height: 26px;
display: flex;
align-items: center;
justify-content: center;
font-size: 14px;
border-radius: 4px;
color: #fff;
background: rgba(20, 182, 133, 1);
        }
        .list_li_logo{
            position: relative;
            width: 30px;
height: 30px;
border-radius: 50%;
             .list_li_logo_box{
                width: 100%;
                height: 100%;
                border-radius: 50%;
             }
             .list_li_logo_abs{
                position: absolute;
                right: -5px;
                bottom: -5px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
             }
        }
    }
}
</style>