<template>
  <div class="QuotesIndex">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in imglist" :key="index">
        <img :src="`${tokenimgurls}${item.image}`" alt="" class="homeimg" />
      </van-swipe-item>
    </van-swipe>
    <div class="hotList">
      <div class="hotList">
        <div class="hotList_box" v-for="(item, index) in hotList" :key="index">
          <div class="hotList_box_name">
            <span class="name1">{{ item.token_name }}</span>
            <span class="name2" style="color: rgba(20, 182, 133, 1)">{{ item.quote_change }}</span>
          </div>
          <div class="hotList_box_num" style="color: rgba(20, 182, 133, 1)">${{ item.last }}</div>
        </div>
      </div>
    </div>
    <van-tabs v-model="active" title-active-color="rgba(255, 255, 255, 1)" title-inactive-color="rgba(102, 102, 102, 1)">
      <van-tab :title="$t('lang.h130')">
        <ListVue :quoteList="quoteList"></ListVue>
      </van-tab>
      <van-tab :title="$t('lang.h131')">
        <ListVue :quoteList="quoteList"></ListVue>
      </van-tab>
      <van-tab :title="$t('lang.h132')">
        <ListVue :quoteList="quoteList"></ListVue>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ListVue from './components/list.vue';
import { homedata } from '@/api/index';

export default {
  data() {
    return {
      active: 0,
      tokenimgurls: ' ',
      imglist: [],
      quoteList: [],
      hotList: [],
    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  components: {
    ListVue,
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getbance();
    },
    getbance() {
      homedata.getbancer(this.account).then(res => {
        console.log(res);
        this.imglist = res.data;
      });
      homedata.gettokenimgurl().then(res => {
        this.tokenimgurls = res.data.image_domain;
        this.gettokenquote();
      });
    },
    gettokenquote() {
      homedata.gettokenquote(this.account).then(res => {
        if (res.code != 200) return;
        let list = res.data || [];
        let hotKeyList = ['BTC', 'ETH', 'RWA'];
        this.hotList = [];
        this.quoteList = [];
        for (let item of list) {
          item.logo = this.tokenimgurls + item.logo;
          if (hotKeyList.includes(item.platform_key)) {
            this.hotList.push(item);
          } else {
            this.quoteList.push(item);
          }
        }
      });
    },
  },
};
</script>
<style scoped lang='less'>
/deep/.van-tabs__wrap {
  border-bottom: 1px solid rgba(51, 51, 51, 1) !important;
}
/deep/.van-tabs__nav {
  width: 132px;
  background: none;
}
/deep/.van-tab--active {
  font-weight: bold;
}
/deep/.van-tabs__line {
  display: none;
}
.QuotesIndex {
  padding: 0 20px;
}
.homeimg {
  height: 154px;
  width: 100%;
  border-radius: 10px;
}
.hotList {
  width: 100%;
  display: flex;
  align-items: center;

  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  .hotList_box {
    .hotList_box_name {
      margin-bottom: 3px;
      .name1 {
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        margin-right: 5px;
      }
      .name2 {
        transform: scale(0.8);
        color: rgba(212, 48, 48, 1);
        font-size: 12px;
      }
    }
    .hotList_box_num {
      font-size: 16px;
      color: rgba(212, 48, 48, 1);
      font-weight: 500;
    }
  }
}
</style>