<template>
  <div class="snowballmain">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in imglist" :key="index">
        <img :src="`${tokenimgurls}${item.image}`" alt="" class="homeimg" />
      </van-swipe-item>
    </van-swipe>
    <!-- <img src="@/assets/img/test_banner.png" alt="" class="homeimg"> -->

    <div class="routerlink">
      <div class="router_li" v-for="(item, index) in routerlist" @click="bNav(item.router, item.i, item.IsOpen)" :key="index">
        <div class="imgbox">
          <div class="imgbox_item">
            <img :src="item.image" alt="" />
          </div>
        </div>

        <span>{{ $t(item.name) }}</span>
      </div>
      <router-link class="notice" :to="{ name: 'xiangqing', query: { id: page.group_id } }">
        <img src="@/assets/imgsnowball/notice.png" alt="" />
        <span style="color: #16caecff">{{ page.title }}</span>
        <router-link to="gongao" class="shoyi"></router-link>
      </router-link>
    </div>
    <p class="d6">{{ $t('lang.d172') }}</p>
    <div class="zhuzao">
      <div class="z_text">
        <span>{{ $t('lang.d173') }}</span
        ><br />
      </div>
      <div class="z_line">
        <span>0</span>
        <div class="z_jingru" @click="bNav('')">{{ $t('lang.d11') }}</div>
      </div>
      <div class="z_text">
        <span style="color: #999999">{{ $t('lang.d174') }}</span
        ><br />
      </div>
      <img src="@/assets/imgsnowball/baobiaob.png" alt="" />
    </div>
    <p class="d6">{{ $t('lang.d12') }}</p>
    <div class="jiasho">
      <div class="jiage box" style="background: #e5f1ff">
        <div class="j_div1">
          <span style="color: #000; font-size: 14px">{{ $t('lang.d175') }}</span>
          <span style="color: #2e71ff; font-size: 14px">$0</span>
        </div>

        <div class="j_div1">
          <img src="@/assets/imgsnowball/left_icon.png" alt="" style="width: 56px; height: 56px" />
          <div @click="bNav('')" class="shandui" style="">{{ $t('lang.d178') }}</div>
        </div>
      </div>
      <div class="jiage box" style="background: #eeeeee">
        <div class="j_div1">
          <span style="color: #000; font-size: 14px">{{ $t('lang.d176') }}</span>
        </div>

        <div class="j_div1">
          <img src="@/assets/imgsnowball/right_icon.png" alt="" style="width: 65px; height: 65px" />
          <div @click="bNav('')" class="shandui" style="background: #000">{{ $t('lang.d179') }}</div>
        </div>
      </div>
    </div>

    <p class="echathead">
      <span class="e_span1">${{ zhangdie.water_price }}</span>
      <span class="e_span2"><img src="@/assets/imgsnowball/arrow up(false).png" alt="" style="transform: rotate(180deg)" />{{ rateshow }}%</span>
    </p>
    <div id="echat" style="width: 100%; height: 260px"></div>

    <div class="zichantitle">
      <span class="title">{{ $t('lang.d16') }}</span>
      <van-checkbox v-model="checked" shape="square" icon-size="14px" checked-color="#477BFD"
        ><span class="yingcang">{{ $t('lang.d17') }}</span></van-checkbox
      >
    </div>
    <div class="zichanlist">
      <div class="assets">
        <div class="a_title">{{ $t('lang.d150') }}</div>
        <div class="a_number">
          <span class="num">{{ guzhi }}</span>
          <span class="tit">USD</span>
        </div>
        <div class="a_shoyi">
          <div class="num">
            <img src="@/assets/imgsnowball/arrow up(false).png" alt="" style="transform: rotate(180deg); width: 10px" v-show="assetshoyi != 0" />

            <span>$ {{ assetshoyi }}</span>
          </div>
          <span class="tit">{{ $t('lang.d14') }}</span>
        </div>
      </div>
      <div class="li_title">
        <div>{{ $t('lang.d18') }}</div>
        <div>{{ $t('lang.d19') }}</div>
        <div>{{ $t('lang.d20') }}</div>
      </div>
      <div v-if="checked == false">
        <div class="li_con" v-for="item in zhicanlist" style="color: #000">
          <div class="li1">
            <img :src="`${tokenimgurls}${item.logo}`" alt="" />
            <span>{{ item.token_name }}</span>
          </div>
          <div>{{ item.freeze }}</div>
          <div>{{ item.usable }}</div>
        </div>
      </div>
      <div v-else>
        <div class="li_con" v-for="item in zhicanlistl" style="color: #000">
          <div class="li1">
            <img :src="`${tokenimgurls}${item.logo}`" alt="" />
            <span>{{ item.token_name }}</span>
          </div>
          <div>{{ item.freeze }}</div>
          <div>{{ item.usable }}</div>
        </div>
      </div>
    </div>
    <div class="overflow" v-show="gongaoshow">
      <div class="o_cont">
        <img src="@/assets/imgsnowball/gongaox.png" alt="" class="gongaox" @click="gongaox" />
        <div class="title">{{ $t('lang.d98') }}</div>
        <div class="line"></div>
        <div class="content">
          <div style="color: #000; font-size: 16px">{{ page.title }}</div>
          <div style="color: #888a8e; font-size: 12px">{{ page.created_time }}</div>
          <div v-html="page.content" style="color: #000; font-size: 14px"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import { gongao, homedata, bizhong, moneymask, zhubi, user } from '@/api/index';
import { mapState } from 'vuex';
export default {
  name: 'Home',
  data() {
    return {
      routerlist: [
        {
          image: require('@/assets/imgsnowball/chobi.png'),
          name: 'lang.d1',
          router: 'ChoBi1',
          i: '0',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/tibi.png'),
          name: 'lang.d2',
          router: 'TiBi2',
          i: '1',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/zhuanzhang.png'),
          name: 'lang.d3',
          router: 'ZhuangZhan3',
          i: '2',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/zhangdan.png'),
          name: 'lang.d4',
          router: 'zhangdan',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/shequ.png'),
          name: 'lang.d5',
          router: 'shequ',
          IsOpen: false,
        },
        {
          image: require('@/assets/imgsnowball/shandui.png'),
          name: 'lang.d6',
          router: 'shandui',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/bangzhu.png'),
          name: 'lang.d7',
          router: 'fankui',
          IsOpen: true,
        },
      ],

      checked: false,
      page: {},
      chartdata: [],
      timedata: [],
      zhangdie: {},
      zhicanlist: [],
      zhicanlistl: [],
      guzhi: 0.0,
      assetshoyi: 0,
      jinrishoyi: {},
      tokenimgurls: '',
      imglist: [],
      gongaoshow: false,
    };
  },
  components() {
    Header;
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState(['account', 'lang', 'gongaoid']),
    rateshow() {
      return this.numMulti(this.zhangdie.rate, 100);
    },
    // d6show (){

    //     return parseFloat(this.jinrishoyi.today_d6_reward).toFixed(2)
    // },
    // ushow (){

    //     return parseFloat(this.jinrishoyi.total_u_reward).toFixed(2)
    // }
  },
  watch: {
    account() {
      //获取账号
      if (!!this.account) {
        this.init();
      }
    },
    lang() {
      this.init();
    },
  },
  mounted() {},
  methods: {
    isfirstgongao() {
      if (this.gongaoid != this.page.group_id) {
        this.$store.commit('SETGONGAOID', this.page.group_id);
        this.gongaoshow = true;
      } else {
        this.gongaoshow = false;
      }
    },
    gongaox() {
      this.gongaoshow = false;
    },
    bNav(router, i, IsOpen) {
      if (!IsOpen) return;
      if (router != '') {
        if (i != '') {
          this.$router.push({ name: router, query: { id: i } });
        } else {
          this.$router.push({ name: router });
        }
      } else {
        // this.$toast(this.$t('lang.swap65'));
      }
    },

    init() {
      this.getnewnotice();
      this.getbance();
      this.getdxingxi();
      this.getchat();
      this.getzichan();
      // this.getjinrishoyi()
      this.getimgurl();
      this.getassetsshoyi();
    },
    charts() {
      var myChart = echarts.init(document.getElementById('echat'));
      // 指定图表的配置项和数据

      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.timedata,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        yAxis: {
          // max: 3500,//最大刻度值
          // interval: 500,//刻度值间隔值

          type: 'value',
          splitLine: {
            lineStyle: {
              color: '#4C7EF9',
            },
          },
        },
        series: [
          {
            name: 'Price',
            type: 'line',
            // barWidth: '60%',
            data: this.chartdata,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      option && myChart.setOption(option);
    },
    getzichan() {
      moneymask.getqianbao(this.account).then(res => {
        this.zhicanlist = res.data;
        try {
          this.zhicanlistl = this.zhicanlist.map((item, index, arr) => {
            this.guzhi += item.equal_usd;
            if (item.usable != 0 || item.freeze != 0) {
              return item;
            } else {
              return '';
            }
          });
        } catch (e) {
          {
          }
        }

        this.zhicanlistl = this.getarraytrim(this.zhicanlistl);
        console.log('22', this.zhicanlistl);
      });
    },
    getarraytrim(array) {
      var arr = [];
      for (var i = 0; i < array.length; i++) {
        if (array[i] != '') {
          arr.push(array[i]);
        }
      }
      console.log(arr);
      return arr;
    },
    getnewnotice() {
      gongao.getNewNotice().then(res => {
        console.log(res);
        this.page = res.data;
        this.isfirstgongao();
      });
    },
    getbance() {
      homedata.getbancer(this.account).then(res => {
        console.log(res);
        this.imglist = res.data;
      });
    },
    getdxingxi() {
      user.gettokenwaterinfo({}, this.account).then(res => {
        console.log(res);
        this.zhangdie = res.data || [];
      });
    },
    getjinrishoyi() {
      zhubi.getjinrishoyi(this.account).then(res => {
        this.jinrishoyi = res.data || [];
        this.jinrishoyi.today_d6_reward = parseFloat(this.jinrishoyi.today_d6_reward).toFixed(2);
        this.jinrishoyi.total_u_reward = parseFloat(this.jinrishoyi.total_u_reward).toFixed(2);
      });
    },
    getassetsshoyi() {
      let data = {
        token_id: '',
      };
      moneymask.getshoyi(data, this.account).then(res => {
        console.log(res);
        this.assetshoyi = res.data;
      });
    },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.tokenimgurls = res.data.image_domain;
      });
    },
    numMulti(num1, num2) {
      var baseNum = 0;
      try {
        baseNum += String(num1).split('.')[1].length;
      } catch (e) {}
      try {
        baseNum += String(num2).split('.')[1].length;
      } catch (e) {}
      return (Number(String(num1).replace('.', '')) * Number(String(num2).replace('.', ''))) / Math.pow(10, baseNum);
    },
    arrzero(arr) {
      console.log(arr.length);
      var len = arr.length;
      for (var i = 0; i < len; i++) {
        arr.pop();
        console.log(arr);
      }
      return arr;
    },
    async getchat() {
      this.timedata = this.arrzero(this.timedata);
      this.chartdata = this.arrzero(this.chartdata);
      let data = {
        days: 7,
      };
      //         this.chartdata = [150, 230, 224, 218, 135, 147, 260]
      //   this.timedata =['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      var a = await user.gettokenwaterchart(data, this.account);
      a = a.data;
      try {
        a.forEach((item, index, arr) => {
          this.timedata.push(item.grant_date);

          this.chartdata.push({
            value: item.eq_usd,
            itemStyle: {
              color: '#4C7EF9',
            },
          });
        });
      } catch (e) {
        {
        }
      }

      await this.charts();
      console.log(this.timedata);

      console.log(this.chartdata);
    },
  },
};
</script>
  
<style lang="less" scoped>
/deep/.van-icon-success {
  color: var( --text-color) !important;
}
.snowballmain {
  padding: 20px;
  margin-top: 50px;
}

.homeimg {
  width: 100%;
  border-radius: 15px;
}

.routerlink {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .router_li {
    width: 25%;

    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .imgbox {
      margin-bottom: 6px;
      width: 64px;
      height: 64px;
      border: 1px solid #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      .imgbox_item {
        border-radius: 50%;
        width: 54px;
        height: 54px;
        opacity: 1;

        background: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 37px;
        }
      }
    }

    span {
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      font-family: 'PingFang';
    }
  }
}

.notice {
  position: relative;
  margin-top: 30px;
  width: 333px;
  height: 60px;
  border-radius: 16px;
  opacity: 1;
  background: #f7f7f7;

  padding: 10px;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    margin-right: 10px;
  }

  span {
    color: var( --text-color);
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
  }

  .shoyi {
    position: absolute;
    right: 20px;
    background: url('~@/assets/imgsnowball/shoyiyou.png') no-repeat;
    width: 36px;
    height: 36px;
    background-size: contain;
  }
}

.d6 {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins';
}

.jiasho {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .box {
    padding: 11px 12px;

    border-radius: 10px;
    height: 100%;
    background: #26272bff;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .jiage {
    .j_span1 {
      color: #ced0deff;
      font-size: 12px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .j_span2 {
      color: var( --text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
    }

    .j_span3 {
      color: #5fc88fff;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .j_div1 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .shandui {
        color: var( --text-color);
        width: 41px;
        height: 21px;
        border-radius: 55px;
        opacity: 1;
        background: #808cf5;
        text-align: center;
        line-height: 21px;
        font-size: 10px;
        font-weight: 500;
        font-family: 'HarmonyOSSansSC';
      }
    }
  }

  .d_shoyi {
    position: relative;

    .s_div {
      align-self: flex-end;
      width: 41px;
      height: 21px;
      border-radius: 55px;
      position: absolute;
      bottom: 10px;
      background: #ffce57ff;
      color: #1c203aff;
      font-size: 10px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
      line-height: 21px;
    }

    .s_span1 {
      color: #ffce57ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .s_span2 {
      color: var( --text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      width: 60%;
      display: flex;
      justify-content: space-between;
      // display: flex;
      // justify-content: space-between;
      // width: 50%;
    }
  }
}

.echathead {
  .e_span1 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-right: 20px;
  }

  .e_span2 {
    color: #9395a4ff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';

    img {
      width: 12px;
      height: 12px;
    }
  }
}

#echat {
  margin: 0 auto;
}

.zhuzao {
  width: 100%;
  height: 140px;
  border-radius: 16px;
  opacity: 1;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #c9dfff 100%);

  box-sizing: border-box;
  border: 1px solid #c9dfff;
  padding: 15px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .z_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;

    color: #3a3a3a;
    span {
      line-height: 21px;
    }
  }
  .z_line {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 36px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0px;

    color: #477bfd;
    .z_jingru {
      width: 91px;
      height: 36px;
      border-radius: 36px;
      opacity: 1;

      background: #4583ff;

      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: center;
      color: var( --text-color);
      line-height: 36px;
    }
  }
  img {
    position: absolute;
    width: 122.01px;
    height: 100px;
    right: 0;
    top: 10px;
  }
}

.zichantitle {
  display: flex;
  align-items: center;
  margin-top: 30px;

  .title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-right: 40px;
  }

  //    /deep/.van-checkbox__icon--square{
  //     position: absolute;
  //     left: 1px;
  //     top: 5px;

  // }
  .yingcang {
    color: #999999ff;
    font-size: 12px;
    font-weight: 500;
    font-family: 'PingFang';
  }

  /deep/.van-checkbox__icon .van-checkbox__icon {
    border-color: #000 !important;
  }

  /deep/.van-checkbox__icon--checked .van-icon-success {
    color: #8b5411;
  }
}

.zichanlist {
  margin-top: 10px;
  width: 100%;
  // height: 213px;
  border-radius: 10px;
  background: var( --text-color);
  border: 1px solid #f0f0f0;
  padding: 17px 14px;

  .li_title {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #999999ff;
    font-size: 12px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.li_con {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  div {
    width: 30%;
    text-align: center;
    line-height: 40px;
  }

  .li1 {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.overflow {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .gongaox {
    position: absolute;
    right: 21px;
  }

  .o_cont {
    position: relative;
    width: 335px;
    min-height: 250px;

    overflow: hidden;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px 21px;
    background: var( --text-color);

    .line {
      background: #44454b;
      width: 100%;
      height: 1px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .title {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 350px;
      overflow-y: scroll;

      div {
        margin-bottom: 10px;
        white-space: pre-wrap;

        white-space: normal;
        word-break: break-word;
      }
    }
  }
}

.assets {
  padding-bottom: 20px;
  width: 100%;

  .a_title {
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
  }

  .a_number {
    .num {
      color: #000;
      font-size: 36px;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .tit {
      margin-left: 5px;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Poppins';
    }
  }

  .a_shoyi {
    display: flex;

    .num {
      color: #c75d5d;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Poppins';
    }

    .tit {
      margin-left: 10px;
      color: #999999;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Poppins';
    }
  }
}
</style>
  