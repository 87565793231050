import { simpleRpcProvider } from '@/utils/provider';
import jackFactoryAbi from '@/config/abi/jackFactory.json';
import jackRouterAbi from '@/config/abi/jackRouter.json';

import jackFactoryAbiV2 from '@/config/abi/FactoryV2.json';
import jackRouterAbiV2 from '@/config/abi/RouterV2.json';

import jackPairAbi from '@/config/abi/jackPair.json';
import wethAbi from '@/config/abi/weth.json';

import erc20Abi from '@/config/abi/erc20.json';
import { getJackFactoryAddress, getJackRouterAddress, getWethAddress,getJackFactoryAddressV2, getJackRouterAddressV2 } from '@/utils/addressHelp';

const getContract = (abi, address, provider) => {
  let signerOrProvider = provider != undefined ? provider : simpleRpcProvider;
  return new signerOrProvider.eth.Contract(abi, address);
};

export const getErc20Contract = (address, provider) => {
  return getContract(erc20Abi, address, provider);
};

export const getJackRouterContract = provider => {
  return getContract(jackRouterAbi, getJackRouterAddress(), provider);
};

export const getJackFactoryContract = provider => {
  return getContract(jackFactoryAbi, getJackFactoryAddress(), provider);
};
export const getJackRouterContractV2 = provider => {
  return getContract(jackRouterAbiV2, '0x10ED43C718714eb63d5aA57B78B54704E256024E', provider);
};

export const getJackFactoryContractV2 = provider => {
  return getContract(jackFactoryAbiV2, '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73', provider);
};

export const getJackPairContract = (address, provider) => {
  return getContract(jackPairAbi, address, provider);
};

export const getWethContract = provider => {
  return getContract(wethAbi, getWethAddress(), provider);
};
