import { render, staticRenderFns } from "./chobi.vue?vue&type=template&id=831aeb5e&scoped=true"
import script from "./chobi.vue?vue&type=script&lang=js"
export * from "./chobi.vue?vue&type=script&lang=js"
import style0 from "./chobi.vue?vue&type=style&index=0&id=831aeb5e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "831aeb5e",
  null
  
)

export default component.exports