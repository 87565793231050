<template>
  <div class="recruit">
    <van-popup v-model:show="contentshow" round closeable>
      <div class="contentshow">
        <div class="contentshow_title">
          <span>{{ $t('lang.h14') }}</span>
        </div>
        <div class="contentshow_box">
          <p style="text-align: left">
            <span>{{ $t('lang.h15') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h16') }}</span>
          </p>
          <p style="text-align: left"><br /></p>
          <p style="text-align: left">
            <span>{{ $t('lang.h17') }} </span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h18') }} {{ ConfigItem.pay_amount }} &nbsp;USDT</span>
          </p>
          <p style="text-align: left"><br /></p>
          <p style="text-align: left">
            <span>{{ $t('lang.h63') }}: </span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h64') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h65') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h66') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h67') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h68') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h69') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h70') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h71') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h72') }}</span>
          </p>
          <p style="text-align: left"><br /></p>
          <p style="text-align: left">
            <span>{{ $t('lang.h73') }} </span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h74') }}</span>
          </p>
          <p style="text-align: left">
            <span>{{ $t('lang.h75') }}</span>
          </p>
        </div>
      </div>
    </van-popup>
    <div class="recruit_title">
      <div style="transform: rotate(90deg)" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px">{{ $t('lang.h29') }}</div>
      <div style="font-size: 12px; opacity: 0.6" @click="contentshowFun">{{ $t('lang.h14') }}</div>
    </div>
    <div class="recruit_content">
      <div class="recruit_title">{{ $t('lang.h76') }}</div>
      <div class="recruit_title_box">
        {{ $t('lang.h77') }}
      </div>
      <div class="recruit_box">
        <div class="recruit_box_line">
          <div class="line"></div>
          <div :class="ActiveIndex == index ? 'recruit_box_line_box_active' : 'recruit_box_line_box'" v-for="(item, index) in PatherList" :key="index">
            <div class="recruit_box_line_box_box"></div>
          </div>
        </div>
        <div class="recruit_box_line" style="margin-top: 7px; margin-bottom: 28px">
          <div v-for="(item,index) in PatherList" :key="index">{{ $t('lang.h31',{ round: item.round }) }}</div>
          
         
        </div>
        <van-slider :disabled="true" :bar-height="14" active-color="rgba(166, 206, 255, 1)" v-model="ConfigItem.sliderRate" inactive-color="rgba(255, 255, 255, 0.2)">
          <template #button>
            <div></div>
          </template>
        </van-slider>
        <div style="width: 100%; font-size: 12px; color: #000; text-align: center; margin-top: 6px">{{ $t('lang.h33') }}:{{ (ConfigItem.sliderRate).toFixed(3) }}%</div>
      </div>
      <van-swipe class="" v-if="PatherList.length > 0" :loop="false" indicator-color="white" :show-indicators="false" @change="change" ref="swiperRef">
        <van-swipe-item class="recruit_boxs" v-for="(item, index) in PatherList" :key="index">
          <div style="padding: 21px 19px" v-if="ConfigItem.status !=4">
            <div class="recruit_boxs_line">
              <span style="opacity: 0.6">{{ $t('lang.h34') }}</span>
              <span>{{ ConfigItem.status == 3 ? '--': item.begin_time }}</span>
            </div>
            <div class="recruit_boxs_line">
              <span style="opacity: 0.6">{{ $t('lang.h35') }}</span>
              <span>{{ ConfigItem.status == 3 ? '--': item.end_time }}</span>
            </div>
            <!-- 
  <div class="recruit_boxs_line">
              <span style="opacity: 0.6">{{ $t('lang.h36') }}</span>
              <span>{{ConfigItem.status == 3 ? '--': item.max_recruit }}</span>
            </div>
             -->
          
            <!-- <div class="recruit_boxs_line">
              <span style="opacity: 0.6">{{ $t('lang.h78') }}:</span>
              <span>{{ ConfigItem.status == 3 ? '--': item.max_recruit - item.recruited }}</span>
            </div> -->
            <div class="recruit_boxs_line">
              <span style="opacity: 0.6">{{ $t('lang.h37') }}</span>
              <span>{{ ConfigItem.status == 3 ? '--': item.pay_amount +'U' }}</span> 
            </div>
          </div>
            <div class="zanwu" v-else >
              <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData == 'black'">
                        <img src="@/assets/img/zanwu1.png" alt="" v-else>
                        <div style="margin-top: 20px;">{{ $t('lang.h86') }}</div>
            </div>
          <div class="account_box">
            <span style="opacity: 0.6">{{ $t('lang.h38') }}: {{ accountBanlce }} U</span>
          </div>
        </van-swipe-item>
      </van-swipe>
     
      <div class="recruit_button" @click="payPopupFun" v-if="ConfigItem.status == 1">{{ $t('lang.h39') }}</div>
      <div class="recruit_button opBUtton" v-else>
        <div v-if="ConfigItem.status == 3">{{ $t('lang.h83') }}</div>
        <div v-else-if="ConfigItem.status == 2">
          <div v-if="DateComd"> {{ $t('lang.h85') }}</div>
          <div v-else>{{ $t('lang.h86') }}</div>
        </div>
        <div v-else-if="ConfigItem.status == 4">{{ $t('lang.h86') }}</div>
      
      </div>
      <div style="margin: 30px 0 20px 0; font-size: 16px; color: var(--text-color); font-weight: 700">{{ $t('lang.h41') }}</div>
      <div class="recruit_list">
        <div class="recruit_list_item">
          <div style="flex-grow: 1.5">{{ $t('lang.h42') }}</div>
          <div>{{ $t('lang.h43') }}</div>
          <div>{{ $t('lang.h44') }}</div>
        </div>
        <div class="recruit_list_item" v-for="(item, index) in LatestList" :key="index">
          <div>{{ item.address }}</div>
          <div>{{ item.pay_amount }}U</div>
          <div>{{ $t('lang.h45') }}</div>
        </div>
      </div>
    </div>
    <van-popup v-model="payPopup" round position="bottom" closeable>
      <div class="payPopup">
        <div class="payPopup_title">
          <div>{{ $t('lang.d190') }}</div>
        </div>
        <div class="payPopup_box">
          <div class="payPopup_box_pay_amount">
            <span style="font-size: 48px; margin-right: 3px">{{ ConfigItem.pay_amount }}</span>
            <span style="font-size: 24px">USDT</span>
          </div>
          <div class="payPopup_box_line" style="margin-top: 20px">
            <img src="@/assets/img/TerraceAccount.png" alt="" class="payPopup_box_line_left" />
            <div class="payPopup_box_line_right">
              <div class="payPopup_box_line_right_left">
                <span style="font-size: 16px; color: #000; margin-bottom: 3px">{{ $t('lang.d193') }}</span>
                <span style="font-size: 12px; color: rgba(153, 153, 153, 1)">{{ $t('lang.d192') }} : {{ accountBanlce }} U</span>
              </div>
              <!-- <div class="payPopup_box_line_right_check">
                <img src="@/assets/img/check_icon_true.png" alt="" v-if="checkIndex == 1" />
                <img src="@/assets/img/check_icon_false.png" alt="" v-else />
              </div> -->
            </div>
          </div>
          <div class="payPopup_box_button" @click="partnerbuy">
            {{ $t('lang.d196') }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { happydex } from '@/api/index';
import Web3 from 'web3';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      ActiveIndex: 0,
      sliderRate: 70,
      contentshow: false,
      payPopup: false,
      PatherList: [],
      ConfigItem: {},
      accountBanlce: 0,
      LatestList: [],
    };
  },
  computed: {
    ...mapState(['account', 'lang','ColorCheckData']),
    DateComd () {
      return new Date().getTime() <= new Date( this.ConfigItem.end_time).getTime()
    },
  },
  components: {},
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  mounted() {
    
    
  },
  methods: {
    change(e) {
      this.ActiveIndex = e;
      this.ConfigItem = this.PatherList[e];
    },
    async partnerbuy() {
      let web3 = new Web3(window.ethereum);
      let sign = '';
      try {
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Partner Buy'), this.account);
      } catch (error) {
        this.$toast(this.$t('lang.h46'));
        return;
      }
      happydex.partnerbuy({ signature: sign }, this.account).then(res => {
        if (res.code == 200) {
          this.payPopup = false;
          this.init();
        } else {
        }
        this.$toast(res.msg);
      });
    },
    toprouter() {
      console.log(this.router);
      this.$router.go(-1);
    },
    contentshowFun() {
      this.contentshow = !this.contentshow;
    },
    payPopupFun() {
      this.payPopup = !this.payPopup;
    },
    async init() {
      await this.partnerconfigs();
      await this.walletinfobykeyUSDT();
      await this.partnerlatestpartners();
      
    },
    partnerlatestpartners() {
      happydex.partnerlatestpartners(this.account).then(res => {
        this.LatestList = res.data;

        this.LatestList.forEach(item => {
          item.address = item.address.slice(0, 5) + '******' + item.address.slice(-5);
        });
      });
    },
    walletinfobykeyUSDT() {
      happydex.walletinfobykey({ token_key: 'USDT' }, this.account).then(res => {
        this.accountBanlce = res.data.usable;
      });
    },
    partnerconfigs() {
      happydex.partnerconfigs(this.account).then(res => {
        let list = [];
        let SIndex = 0;
        let SIndexStatus =  true;
        if (res.code == 200) {
          list = res.data;
         
          list.forEach(item => {
            item.sliderRate = ((item.recruited / item.max_recruit) * 100);
          });
        
        list.forEach((item, index) => {
            //    加入 1  本期已招满 2    本期未开启 3   本期已结束  4
            
            let NowTime = new Date().getTime();
            let StartTime = new Date(item.begin_time).getTime();
            let EndTime = new Date(item.end_time).getTime();
          
            if (item.can_buy) {
              if (NowTime < EndTime) {
                if (item.max_recruit == item.recruited) {
                  //本期已招满
                  item.status = 2;
                
                } else {
                  //加入
                    if(NowTime >= StartTime){
                      //加入
                      item.status = 1;
                    }else{
                      //本期未开启
                      item.status = 3;
                    }
                 
                }
              }else{

              }
            } else {
              //现在大于结束时间 已结束
              if (NowTime > EndTime) {
                item.status = 4;
              } else {
                //本期未开启
                if (item.max_recruit == item.recruited) {
                  //本期已招满
                  item.status = 2;
                
                } else {
                  //本期未开启
                  item.status = 3;
                 
                }
              }
            }
          
          });
         
          // if (SIndex+ 1<list.length) {
          //        // 下一项有数据
          //    for(var i = SIndex+1; i<list.length; i++){
          //              this.PatherList.push(list[i]);
          //    }
          //   }else{
          //        // 下一项没数据
          //        this.PatherList.push(list[SIndex])
          //   }
           this.PatherList = list
          this.ActiveIndex = 0;
          this.ConfigItem = this.PatherList[0];
          this.$nextTick(() => {
            this.PatherList.forEach((item,index)=>{
                   if(item.can_buy){
                    console.log(this.$refs.swiperRef);
                         this.$refs.swiperRef.swipeTo(index);
                         this.ActiveIndex = index;
                         this.ConfigItem = item;
                   }
           })
          })
        }
      });
    },
  },
};
</script>
<style scoped lang='less'>
/deep/.van-slider--disabled {
  opacity: 1 !important;
}
.zanwu{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 height: 267px;
 color: var(--text-color);
  img{
    width: 82.88px;
height: 141.7px;
  }
}
.recruit {
  padding-top: 52px;
  background: var(--bg-color);
}
.slider_button {
  font-size: 12px;
  color: #000;
  transform: translateX(-50%) scale(0.8);
}
.recruit_title {
  color: var(--text-color);
  padding: 0 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recruit_content {
  padding: 20px;
  width: 100%;
  border-radius: 30px 30px 0px 0px;
  background: var(--box-bg-color);
  .recruit_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
  }
  .recruit_title_box {
    padding: 7px 11px;
    border: 1px solid rgba(52, 247, 244, 0.49);
    border-radius: 16px;
    margin-bottom: 23px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;
    color: var(--text-color);
    text-align: left;
    vertical-align: top;
  }
  .recruit_box {
    padding: 25px 15px;
    width: 100%;
    height: 123px;
    opacity: 1;
    border-radius: 16px;
    background: linear-gradient(136.98deg, rgba(33, 217, 214, 1) 0%, rgba(111, 69, 173, 1) 100%);
    .recruit_box_line {
      color: #fff;
      font-size: 13px;
      font-weight: 700;
      position: relative;
      width: 100%;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .recruit_box_line_box {
        z-index: 99;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.8);
        .recruit_box_line_box_box {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 1);
        }
      }
      .recruit_box_line_box_active {
        z-index: 99;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(84, 10, 255, 1);
        .recruit_box_line_box_box {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: rgba(63, 0, 209, 1);
        }
      }

      .line {
        position: absolute;
        width: 100%;
        height: 1px;
        background: url(~@/assets/img/recruit_box_line.png);
      }
    }
  }
  .recruit_boxs {
    margin: 20px 0;
    width: 100%;
    color: var(--text-color);
    font-size: 14px;
    border-radius: 16px;
    .account_box {
      padding: 21px 19px;
      border-top: 1px solid rgba(65, 79, 105, 1);
    }
    background: var(--line-bg-color);
    .recruit_boxs_line {
      margin-bottom: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  
  .recruit_button {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    opacity: 1;
    border-radius: 110px;
    background: rgba(152, 232, 110, 1);
  }
  .opBUtton{
    color: rgba(153, 153, 153, 1);
    background: var(--line-bg-color);
  }
  .recruit_list {
    width: 100%;
    .recruit_list_item {
      font-size: 12px;
      color: var(--text-color);
      padding-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        flex-grow: 1;
      }
      & > div:first-child {
        text-align: left;
        flex-grow: 1;
      }
      & > div:nth-child(2) {
        flex-grow: 1.5;
        text-align: center;
      }
      & > div:last-child {
        text-align: right;
      }
    }
  }
}
.contentshow {
  width: 335px;
  .contentshow_title {
    padding: 20px;
    background: #fff;
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    font-size: 16px;
    color: rgba(34, 34, 34, 1);
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 21px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contentshow_box {
    background: #fff;
    padding: 20px;
    padding-bottom: 40px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0px;
    max-height: 330px;
    overflow-y: scroll;
    color: rgba(34, 34, 34, 1) !important;
  }
}
.payPopup {
  width: 100%;

  .payPopup_title {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21px;
    color: rgba(34, 34, 34, 1);
    border-bottom: 1px solid rgba(242, 242, 242, 1);

    img {
      width: 13.44px;
      height: 13.44px;
    }
  }

  .payPopup_box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .payPopup_box_button {
      margin-top: 36.4px;
      width: 335px;
      height: 60px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(152, 232, 110, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18px;
      color: #000;
    }

    .payPopup_box_pay_amount {
      margin-top: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 36px;
      color: rgba(34, 34, 34, 1);
    }

    .payPopup_box_line {
      width: 100%;
      display: flex;

      .payPopup_box_line_left {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .payPopup_box_line_right {
        padding-bottom: 13px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(242, 242, 242, 1);

        .payPopup_box_line_right_left {
          display: flex;
          text-align: left;
          flex-direction: column;
        }

        .payPopup_box_line_right_check {
          width: 22px;
          height: 22px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>