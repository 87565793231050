<template>
  <div style="background: rgba(16, 20, 32, 1);padding-top: 30px;">
    <div class="title" style="padding: 0 20px; width: 100%; align-items: center; display: flex; justify-content: space-between;">
        <topbutton router="fankui" ></topbutton>
        <span>{{$t('lang.d115')}}</span>
        <div></div>
    </div>
    <div class="fanx" style="border-radius: 20px 20px 0 0;">
    
   <div class="cont">
      <div class="c_main">
       <div class="account">{{addressshow}}{{ $t('lang.d125') }}</div>
       <div class="lin"></div>
       <div class="fantext">
        <div style="font-size: 14px; color: var( --text-color); margin-bottom: 5px;">
            {{itemlist.content}}
        </div>
        <div style="font-size: 12px; color: #999;">
          {{ itemlist.created_time }}
        </div>
       </div>
       <div class="imgs">
        <van-image
         class="img"
        v-for="item in imglist"
  width="61"
  height="61"
  :error-icon ="errorIcon"
  :src="`${imgurl}${item}`"
  @click="imgPreview(`${imgurl}${item}`)"
/>

       </div>
      </div>
      <div class="kefu">
     <div class="line1">
        <img src="@/assets/imgsnowball/kefu.png" alt="">
        <span>{{$t('lang.d116')}}</span>
     </div>
     <div class="lin"></div>
     <div class="text" v-if="huifulist.length ==0"> <p>
        {{ $t('lang.d117') }}
     </p> </div>
     <!-- <div class="text">回复：{{ huifulist.content }}</div> -->
     <div class="text" v-else><div> {{ $t('lang.d118') }}{{ huifulist.content }}</div>
        <div style="font-size: 12px; color: #87888C;">{{ huifulist.created_time }}</div>
    </div>
      </div>
   </div>
  </div>
  </div>
 
</template>

<script>
import topbutton from '@/components/topbutton.vue';
import { ImagePreview } from 'vant';
import { mapState } from 'vuex';
import { basic,homedata } from '@/api/index'
export default {
  name: '',
  components: {
    topbutton
  },
  data() {
    return {
           errorIcon:require("@/assets/imgsnowball/imgshow.png"),
           itemlist:{},
           imglist:[],
           id:"",
           imgurl:"",
           huifulist:[]
    }
  },
  watch: {
    account () {
      //获取账号
      if(!!this.account){
        this.init();
      }
      
    },
  lang (){
    
    this.init();
   
  },
},
  computed: {
    ...mapState(['account','lang']),
    addressshow (){
        const head =this.account.slice(0,4);
    const tail =this.account.slice(this.account.length -4);
    return head +"****"+tail;
      },
  },
  created() {
           if(this.$route.query.data){
              this.itemlist =this.$route.query.data ||{}
              this.imglist =this.$route.query.data.image_arr || []
              this.id =this.$route.query.data.id 
              this.init()
           }
  },
  methods: {
    imgPreview (data){
        ImagePreview([data]);
    },
    init (){
        let data ={
            id :this.id
        }
       basic.getbasicrelylist(data,this.account).then(res =>{
             console.log(res);
       })
       this.getimgurl()
    },
    getimgurl() {
            homedata.gettokenimgurl().then(res => {
                this.imgurl = res.data.image_domain
            })
        },
  },
}
</script>

<style lang="less" scoped>
.fanx{
    width: 100%;
    height: 100vh;
    background: rgba(16, 20, 32, 1);
}
.title{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
   
    
    
   
    span{
        color: var( --text-color);
 font-size: 18px;
 font-weight: 700;
 font-family: "PingFang";
    }
}
.cont{
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    .c_main{
        width: 100%;
border-radius: 20px;
opacity: 1;
background: rgba(25, 29, 41, 1);

box-sizing: border-box;
padding: 15px 10px;
.account{
    color: var( --text-color);
 font-size: 12px;
 font-weight: 500;
 font-family: "PingFang";
}
.lin{
    width: 100%;
    height: 1px;
    background: var( --text-color);
    margin-top: 20px;
    margin-bottom: 10px;
}
    }
}
.fantext{
    width: 100%;
    div{
        white-space: normal;
  word-break: break-word;   
    }
}
.imgs{
    display: flex;
    margin-top: 10px;
   .img{
    margin-right: 10px;
   }
}
/deep/.van-icon__image{
    width: 100%;
    height: 100%;
}
/deep/.van-image{
    border-radius: 3px;
}
/deep/.van-image__error{
    background: none;
}
/deep/.van-image__img{
    border-radius: 3px !important;
}
.kefu{
    height: 104px;
border-radius: 20px;
opacity: 1;
background: rgba(25, 29, 41, 1);

margin-top: 12px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
padding: 16px 10px;
.line1{
    width: 100%;
    display: flex;
    align-items: center;
    color: var( --text-color);
 font-size: 12px;
 font-weight: 500;
 font-family: "PingFang";
    img{
        width: 26px;
        height: 26px;
        margin-right: 10px;
    }
}
.lin{
    width: 100%;
    height: 1px;
    background: var( --text-color);
}
.text{
    width: 100%;
    color: var( --text-color);
 font-size: 14px;
 font-weight: 500;
 font-family: "PingFang";
 white-space: normal;
  word-break: break-word;
}
}
</style>