<template>
  <div class="index_popup">
    <div v-show="swapPopupData.swapQuantity =='0'">
      <div class="head">
        <span>{{ $t('lang.swap27') }}</span>
        <img src="@/assets/img/cancel.png"
             class="cancel"
             @click="close" />
      </div>
      <div class="content">
        <div class="img">
          <img src="@/assets/img/exclamatory_mark.png"
               class="exclamatory_mark" />
        </div>
        <div class="text">
          <span>China Merchants Capital Investment Co., Ltd. referred to as "China Merchants</span>
        </div>
        <div class="button">
          <van-button type="primary"
                      class="confirmBtn"
                      @click="cancelButton">
            <span>{{ $t('lang.swap90') }}</span>
          </van-button>

          <van-button type="primary"
                      class="confirmBtn"
                      @click="close">
            <span>{{ $t('lang.swap154') }}</span>
          </van-button>
        </div>
      </div>
    </div>
    <div v-show="swapPopupData.swapQuantity =='1'">
      <div class="head">
        <span>{{swapPopupData.swapTitle}}</span>
        <img src="@/assets/img/cancel.png"
             class="cancel"
             @click="close" />
      </div>
      <div class="content">
        <div class="img">
          <img src="@/assets/img/exclamatory_mark.png"
               class="exclamatory_mark" />
        </div>
        <div class="text">
          <span>{{swapPopupData.swapContent}}</span>
        </div>
        <div class="button">
          <van-button type="primary"
                      class="confirmBtn"
                      @click="close">
            <span>{{ $t('lang.swap154') }}</span>
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['swapPopupData', 'swapTitle', 'swapContent'],
  data () {
    return {};
  },
  methods: {
    cancelButton () {
      this.$emit('cancelButton');
    },
    close () {
      this.$emit('switch');
    },
  },
};
</script>

<style lang="less" scoped>
.index_popup {
  // width: 350px;
  background: var( --box-bg-color);
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;

    color: var( --text-color);
    padding: 16px 22px;
    .cancel {
      width: 16px;
    }
  }
  .content {
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    padding: 10px 24px 16px;
    .img {
      display: flex;
      justify-content: center;
      .exclamatory_mark {
        width: 40px;
      }
    }
    .text {
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin: 10px 0 20px;
    }
    .button {
      display: flex;
      justify-content: space-around;
      .confirmBtn {
        height: auto;
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #000;
        border-radius: 112px;
background: rgba(152, 232, 110, 1);
        padding: 10px 24px;
      }
    }
  }
}
</style>
