import request from '../utils/request';
export default {
    //提币订单
    getwithdrawlist(data,address,language) {
        return request.request({
            url: '/withdraw/order/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
            
        })
    },
    //提币
    postwithdraworder(data,address,language) {
        return request.request({
            url: '/withdraw/order/wallet-out',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
    //提币条件
    getwithdrawcondition(data,address,language) {
        return request.request({
            url: '/withdraw/order/condition',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
            
        })
    },
    //创建提币地址
    postwithdrawcreate(data,address,language) {
        return request.request({
            url: '/withdraw/address/create',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
    //提币地址列表
    getwithdrawaddresslist(address,language) {
        return request.request({
            url: '/withdraw/address/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
         
            
        })
    },
    //修改提币地址
    postwithdrawaddressupdate(data,address,language) {
        return request.request({
            url: '/withdraw/address/update',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
    //删除提币地址
    postwithdrawaddressdelete(data,address,language) {
        return request.request({
            url: '/withdraw/address/delete',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
}